import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import colors from '../../../assets/colors'
import React from 'react'

export const ButtonStyled = styled(props => <Button {...props} />)`
    cursor: ${ props => props.disabled ? 'not-allowed' : 'pointer'};
    pointer-events: ${ props => props.disabled ? 'none' : 'auto'};
    opacity: ${ props => props.disabled ? 0.4 : 1};
    padding: 9px 12px 9px 10px !important;
    margin-left: 12px !important;
    // background: #FFFFFF !important;
    border: ${props => props.remove ? '1.5px solid #F4304A' : `1.5px solid ${colors.grey3}`} !important;
    box-sizing: border-box !important;
    border-radius: 6px !important;

    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: ${props => props.remove ? '#F4304A' : colors.grey3} !important;
    height: 36px !important;
    position: ${props => props.isDeleteOfferBtn ? 'absolute' : 'relative'};
    right: ${props => props.isDeleteOfferBtn ? '18px' : 0};

    span{
        color: ${props => props.remove ? '#F4304A' : colors.grey3} !important;
    }

    svg{
        margin-right: ${props => props.isDeleteOfferBtn ? 0 : '8px'};
        fill: ${colors.white}
    }
`