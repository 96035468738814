import React, { useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { RegularButton } from '../../atoms'
import qs from 'qs'
import _ from 'lodash'
import moment from 'moment'
import * as api from '../../../api'
import * as S from './styled'
// import Typography from '@material-ui/core/Typography'
// import CircularProgress from "@material-ui/core/CircularProgress";

const LandingSmsText = withRouter(({ classes, location, i18n, history }) => {
  const search = decodeURIComponent(_.get(location, 'search'))
  const params = qs.parse(search, { ignoreQueryPrefix: true })
  const [citationData, setCitationData] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const [isError, setIsError] = useState(false)

  const [personName, setPersonName] = useState("");
  const [jobName, setJobName] = useState("");
  const [clientId, setClientId] = useState("");

  const data = useCallback(async () => {
    try {
      setIsFetching(true)
      const response = await api.getCitationDataByCitationId(params.citationId, params.pin, params.application)
      if(response.data.hasOwnProperty('url_meeting') && response.data.url_meeting !== null){
        window.location.href = response.data.url_meeting;
      }
      let responseData = _.isObject(response) && Object.keys(response).includes('data') ? response.data : response;
      setCitationData(responseData);

      let name = "";
      if (responseData.candidature && responseData.candidature.nombre) {
        name = responseData.candidature.nombre;
      }
      if (!name && responseData.job_offer_application && responseData.job_offer_application.person && responseData.job_offer_application.person.nombre) {
        name = responseData.job_offer_application.person.nombre;
      }
      setPersonName(name);

      let jobName = "";
      if (responseData.candidature && responseData.candidature.candidatura && responseData.candidature.candidatura.name) {
        jobName = responseData.candidature.candidatura.name;
      }
      if (!jobName && responseData.job_offer_application && responseData.job_offer_application.job_offer && responseData.job_offer_application.job_offer.name) {
        jobName = responseData.job_offer_application.job_offer.name;
      }
      setJobName(jobName);

      let client = "";
      if (responseData.candidature && responseData.candidature.client && responseData.candidature.client.id) {
        client = responseData.candidature.client.id;
      }
      if (!client && responseData.job_offer_application && responseData.job_offer_application.client && responseData.job_offer_application.client.id) {
        client = responseData.job_offer_application.client.id;
      }
      setClientId(client);

      setIsFetching(false)
    } catch (e) {
      setIsFetching(false)
      setIsError(true)
    }
  }, [params.citationId, params.pin, params.application])

  useEffect(() => {
    data()
    // eslint-disable-next-line
  }, [])

  function stripHtml(html) {
    const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g; // eslint-disable-line
    html =  html.replace(Rexp, "<a href='$1' target='_blank'>$1</a>");

    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.innerHTML; //tmp.innerText || ''
  }

  return (
    <Container component="main" className={classes.root}>
      {isFetching && (
        <S.InfoContainer>
          <S.Text>Cargando...</S.Text>
        </S.InfoContainer>
      )}

      {citationData && Object.keys(citationData).length && !isFetching ? (
        <>
          <S.InfoContainer>
            <S.Text>
              Hola <span className="bold">{personName}</span>,
            </S.Text>
            <S.Text>
              Queremos informarte que tras evaluar tu perfil, nos gustaría avanzar contigo a la siguiente fase del
              proceso de selección para la posición de <span className="bold">{jobName}</span>.
            </S.Text>
            <S.Text className="uppercase bold">Información de la convocatoria</S.Text>
            <S.Text>
              <span className="bold">Dirección:</span> {citationData.location}
            </S.Text>
            <S.Text>
              <span className="bold">Persona de contacto:</span> {citationData.interviewer}
            </S.Text>
            <S.Text>
              <span className="bold">Fecha de la entrevista:</span> {moment(citationData.citation_date).utc().format('L')}
            </S.Text>
            <S.Text>
              <span className="bold">Hora de la entrevista:</span> {moment(citationData.citation_date).utc().format('LT')}
            </S.Text>
            {citationData.message ? <S.Text className={classes.message} dangerouslySetInnerHTML={{ __html: stripHtml(citationData.message) }} /> : ''}
            <S.Text className="align-center">
              <span className="bold">Por favor, envíanos tu respuesta a esta convocatoria, seleccionando una de las siguientes opciones:</span>
            </S.Text>
          </S.InfoContainer>
          <S.ButtonsContainer>
            <S.ButtonContainer>
              <RegularButton
                title={i18n.landingEmail.confirmed}
                color="#1BB247"
                onClick={() => {
                  history.push(`/landing-email?citationId=${params.citationId}&statusId=4&pin=${params.pin}&application=${params.application}`)
                }}
              />
            </S.ButtonContainer>
            {clientId !== "463e65b1-a2e3-11ed-beb6-029a57de6221"
                && <S.ButtonContainer>
              <RegularButton
                title={i18n.landingEmail.reschedule}
                color="#E3A335"
                onClick={() => {
                  history.push(`/reschedule?citationId=${params.citationId}&statusId=6&pin=${params.pin}&application=${params.application}`)
                }}
              />
            </S.ButtonContainer>}
            <S.ButtonContainer>
              <RegularButton
                title={i18n.landingEmail.not_interested}
                color="#D3241F"
                onClick={() => {
                  history.push(`/not-interested?citationId=${params.citationId}&statusId=5&pin=${params.pin}&application=${params.application}`)
                }}
              />
            </S.ButtonContainer>
          </S.ButtonsContainer>
        </>
      ) : (
        ''
      )}
      {isError ? (
        <S.InfoContainer>
          <S.Warning className="align-center">No existe la citación</S.Warning>
        </S.InfoContainer>
      ) : (
        ''
      )}
    </Container>
  )
})

LandingSmsText.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default LandingSmsText
