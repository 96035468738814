import _ from 'lodash'
import moment from 'moment'
import { AuthActions } from './'
import { CitationsOperations } from '../citations'
import { SaveSearchesOperations} from "../savesearches";
import { TOKEN_KEY } from '../../config/constants'

export const checkSession = () => (dispatch, getState, { api }) =>
  new Promise(resolve => {
    const token = localStorage.getItem(TOKEN_KEY)
    const valid_token = localStorage.getItem('valid_token')
    if(token && (!valid_token || (+valid_token) < (+new Date()))){
      window.forceLogout();
      if(localStorage.getItem("login_url")) {
        window.location.href = localStorage.getItem("login_url");
      }
    }else{
        api.configureToken(token)
        api.configureInterceptors(() => dispatch(signOut()))
        if (token) {
            dispatch(fetchCommonData())
        }
    }

    resolve()
  })
//We have remved the parameter history, next to the password: export const signIn = ({ email, password }, history)
export const signIn = ({ email, password }) => (dispatch, getState, { api, i18n }) =>
  new Promise((resolve, reject) => {
    let token = null
    api
      .sigIn({ username: email, password: password })
      .then(response => {
        dispatch(AuthActions.setIsFetching(true))
        token = response.token
        localStorage.setItem(TOKEN_KEY, token)
        const date = new Date();
        localStorage.setItem('valid_token', date.setHours(date.getHours()+12))
        api.configureToken(token)
      })
      .then(() => {
        dispatch(fetchCommonData())

        resolve()
      })
      .catch(() => {
        dispatch(AuthActions.setIsFetching(false))
        reject(reject(new Error(i18n.login.noValidCredentials)))
      })
  })

export const signOut = () => dispatch =>
  new Promise(resolve => {

    //We delete the savedFilters of the search
    dispatch(SaveSearchesOperations.filtersClear());
    dispatch(AuthActions.logOut());
    window.forceLogout && window.forceLogout();
    localStorage.removeItem("hrbf.data")

    resolve()
  })

export const updateLocale = newLenguaje => (dispatch, getState, { i18n }) =>
  new Promise(resolve => {
    i18n.locale = newLenguaje
    i18n.setLanguage(newLenguaje)
    dispatch(AuthActions.setLanguaje(newLenguaje))
    resolve()
  })

export const fetchMe = () => (dispatch, getState, { api, handleError }) => {
  dispatch(AuthActions.setIsFetching(true))

  return api
    .getMeProfile()
    .then(data => {
      dispatch(AuthActions.setData(data))
      dispatch(AuthActions.setUrl(data.avatar))
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

const fetchCommonData = () => (dispatch, getState, { api, handleError, i18n }) => {
  Promise.all([api.getMeProfile()])
    .then(([profile]) => {
        profile.client_id = profile.client.id;
      dispatch(AuthActions.setClient(profile.client))
      dispatch(CitationsOperations.fetchCitationsResponsible())
      dispatch(AuthActions.setData(profile))
      dispatch(AuthActions.setUrl(profile.avatar))
      dispatch(AuthActions.setEmail(profile.email))
      i18n.locale = profile.client.lang
      i18n.setLanguage(profile.client.lang)
      moment.locale(profile.client.lang)
      if (!_.isEqualWith(profile.client, getState().auth.client)) {
        dispatch(AuthActions.setClient(profile.client))
      }
      localStorage.setItem('client', profile.client.id);
      localStorage.setItem("hrbf.data", JSON.stringify({
        "nombre": profile.name,
        "apellidos": profile.surname,
        "email": profile.email,
        "client_id": profile.client.id,
        "cliente": profile.client.name,
      }));

      dispatch(AuthActions.setSesionLoaded(true))
      dispatch(AuthActions.setIsFetching(false))
    })
    .catch(error => {
        // localStorage.removeItem('token');
        // window.location.reload();
        dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(dispatch(AuthActions.setIsFetching(false))))
}

export const updateAvatar = imageBase64 => (dispatch, getState, { api, handleError, toast, i18n }) => {
  dispatch(AuthActions.setIsFetching(true))

  const { email, name, surname } = _.get(getState().auth, 'data', {})

  return api
    .putSelf({ imageBase64, email, name, surname })
    .then(() => {
      toast(i18n.myProfile.avatarUpdated, { type: toast.TYPE.SUCCESS })
      dispatch(fetchMe())
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const updateProfile = data => (dispatch, getState, { api, handleError, toast, i18n }) => {
  dispatch(AuthActions.setIsFetching(true))

  const { email, name, surname, lang } = data

  const currentLang = i18n.locale;

  return api
    .putSelf({ email, name, surname, lang })
    .then(() => {
      toast(i18n.myProfile.profileUpdated, { type: toast.TYPE.SUCCESS })

      if (currentLang !== lang) {
        i18n.locale = lang;
        i18n.setLanguage(lang);
        dispatch(AuthActions.setLanguaje(lang));
      }

      dispatch(fetchMe());
    })
    .catch(error => {
      dispatch(handleError(error))
      throw error
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const changePassword = data => (dispatch, getState, { api, toast, i18n }) => {
  dispatch(AuthActions.setIsFetching(true))

  return api
    .changePassword(data)
    .then(() => {
      toast(i18n.myProfile.passUpdated, { type: toast.TYPE.SUCCESS })
    })
    .catch(error => {
      toast(i18n.myProfile.passUpdatedError, { type: toast.TYPE.ERROR })
      throw error
    })
    .finally(() => dispatch(AuthActions.setIsFetching(false)))
}

export const integrateWithTeams = () => (dispatch, getState, { api }) => {

    return api.integrateWithTeams()

}

export const disconnectIntegration = (integration, id) => (dispatch, getState, { api }) => {
    return api.disconnectIntegration(integration.event_type, id);
}
