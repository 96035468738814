import React, { useEffect, useState } from 'react'
import compose from 'recompose/compose'
import { withI18n } from '../../hocs'
import Grid from '@material-ui/core/Grid'
import { ModalStyled } from './styled'
import Modal from '@material-ui/core/Modal'
import { ModalHeaderProcess, RegularButton, RegularButtonOutline, InputField, CustomAlert } from '../../atoms'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useMutation } from "@tanstack/react-query";
import { postRpaIntegration, recoverRpaPassword, patchRpaIntegration } from '../../../api/index';
import { INTEGRATIONS_RPA } from '../../../config/constants';
import { useRpaCandidatures } from '../../../api/queries/useRpaIntegration';
import { useRpaKeyword } from '../../../api/queries/useRpaIntegration';
import { CircularProgress } from '@material-ui/core'
import ModalConfirmIntegration from '../modal-confirm-integration'
import { Tooltip } from '@material-ui/core';
import ModalConfirmLogin from '../modal-confirm-login/index'
import Switch from '@material-ui/core/Switch';
import {isArray} from "lodash";

const customStyles = {
  overflow: 'scroll'
}

const ModalNewIntegration = ({ open, handleClose, i18n, isEdit, editingIntegration, self_data, onDataUpdate }) => {

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormValidEdit, setIsFormValidEdit] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertPassword, setShowAlertPassword] = useState(false);
  const [alert, setAlert] = useState({ message: '', severity: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [confirmIntegration, setConfirmIntegration] = useState(false);
  const [formData, setFormData] = useState({
    positionUser: '',
    recruitmentSource: { id: '', name: '' },
    concatUrl: '',
    ePreselecUrl: '',
    username: '',
    passwordUser: '',
    generatedKeyword: '',
  });
  const [keywordRequested, setKeywordRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialUser, setInitialUser] = useState('');
  const [initialPassword, setInitialPassword] = useState('');
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [valueUrl, setValueUrl] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPasswordValidated, setIsPasswordValidated] = useState(false);
  const [isOpenEye, setIsOpenEye] = useState(false);
  const [showAdvancedConfig, setShowAdvancedConfig] = useState(false);

  const { data: rpaCandidatures, isLoading, refetch } = useRpaCandidatures(self_data.client_id);

  const { refetch: refetchKeyword } = useRpaKeyword(
    self_data.client_id,
    formData.positionUser ? formData.positionUser.id : null,
    formData.recruitmentSource ? formData.recruitmentSource.id : null,
    keywordRequested
  );

  useEffect(() => {
    if (open) {
      setIsOpen(true);
      resetModalValues();
    }
  }, [open]);

  useEffect(() => {
    if (alert.message) {
      setShowAlert(true);
      setShowAlertPassword(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setShowAlertPassword(false);
        setAlert({ message: '', severity: '' });
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const validateForm = () => {
    const isValid = formData.positionUser && (formData.recruitmentSource || formData.ePreselecUrl) && formData.username && formData.passwordUser;
    setIsFormValid(isValid);
  };

  const validateFormEdit = () => {
    const isValidEdit = formData.username && formData.passwordUser;
    setIsFormValidEdit(isValidEdit);
  };

  const options = [
    { id: INTEGRATIONS_RPA.INFOJOBS_PROVIDER, name: i18n.keywords_rpa.sourceInfojobs },
    { id: INTEGRATIONS_RPA.EPRESELEC_PROVIDER, name: i18n.keywords_rpa.sourceEpreselec },
    { id: INTEGRATIONS_RPA.COMPUTRABAJO_PROVIDER, name: i18n.keywords_rpa.sourceComputrabajo },
    { id: INTEGRATIONS_RPA.PANDAPE_PROVIDER, name: i18n.keywords_rpa.sourcePandape },
    { id: INTEGRATIONS_RPA.TURIJOBS_PROVIDER, name: i18n.keywords_rpa.sourceTurijobs },
  ];

  const optionsPosition = rpaCandidatures ? rpaCandidatures.map((candidature) => ({
    id: candidature.id,
    name: candidature.name,
  })) : [];

  const createIntegration = async (data) => {
    try {
      const response = await postRpaIntegration(data, self_data.client_id);
      return response.data;
    } catch (error) {
      if (error && error.code === 500) {
        setAlert({ message: i18n.user_management.userAlreadyExists, severity: 'error' });
      } else {
        console.error("Error while creating/updating user:", error);
        setAlert({ message: i18n.user_management.errorCreateIntegration, severity: 'error' });
        setIsDisabledButton(true);
      }
      throw error;
    }
  }

  const updateIntegration = async (data) => {
    try {
      const response = await patchRpaIntegration(editingIntegration.id, self_data.client_id, data);
      return response.data;
    } catch (error) {
      setIsDisabledButton(false);
      throw error;
    }
  }

  const recoverPassword = async () => {
    const { id } = editingIntegration;
    const response = await recoverRpaPassword(id, self_data.client_id);
    return response;
  }

  const handleNewIntegration = async (e) => {
    e.preventDefault();
    if (!formData.positionUser || (typeof formData.positionUser === 'object' && Object.keys(formData.positionUser).length === 0)) {
      setAlert({ message: i18n.keywords_rpa.positionRequired, severity: 'info' });
      return;
    }
    if (!formData.recruitmentSource.name || (typeof formData.recruitmentSource === 'object' && Object.keys(formData.recruitmentSource).length === 0)) {
      setAlert({ message: i18n.keywords_rpa.sourceRequired, severity: 'info' });
      return;
    }

    if (formData.recruitmentSource.name === 'ePreselec') {
      const trimmedValueUrl = valueUrl.trim().toLowerCase();
      const constructedUrl = `https://${trimmedValueUrl}.admin.epreselec.com/es/vacantes`;

      setFormData({
        ...formData,
        ePreselecUrl: constructedUrl,
      });

      if (constructedUrl === '') {
        setAlert({ message: i18n.keywords_rpa.urlRequired, severity: 'info' });
        return;
      }

      const urlRegex = /^https:\/\/[a-z]+\.admin\.epreselec\.com\/es\/vacantes$/;

      if (!urlRegex.test(constructedUrl)) {
        setAlert({ message: i18n.keywords_rpa.invalidUrl, severity: 'error' });
        return;
      }
    }

    if (formData.username.trim() === '') {
      setAlert({ message: i18n.keywords_rpa.usertRequired, severity: 'info' });
      return;
    }

    if (formData.passwordUser.trim() === '') {
      setAlert({ message: i18n.keywords_rpa.passwordRequired, severity: 'info' });
      return;
    }

    if (formData.generatedKeyword.trim() === '') {
      setAlert({ message: i18n.keywords_rpa.generatedKeywordRequired, severity: 'info' });
      return;
    }

    try {
      if (isEdit) {
        const data = {
          user: formData.username,
          password: formData.passwordUser,
        };
        setConfirmIntegration(true);
        setIsPasswordValidated(true)
      } else {
        const data = {
          candidature: formData.positionUser.id,
          provider: formData.recruitmentSource.id,
          concat_url: formData.concatUrl,
          user: formData.username,
          password: formData.passwordUser,
          keyword: formData.generatedKeyword,
          login_url: formData.ePreselecUrl,
        };
        setConfirmIntegration(true);
      }
    }
    catch (error) {
      console.error('Error al crear la integración:', error);
    }
  };

  const handleInputChange = (e, fieldName) => {
    const { value } = e.target;

    if (fieldName === "ePreselecUrl") {
      setValueUrl(value);
    } else {
      setFormData({ ...formData, [fieldName]: value });
    }

    if (isEdit) {
      validateFormEdit();
    } else {
      validateForm();
    }
  };

  const handleChangeSource = (value) => {
    setFormData({
      ...formData,
      recruitmentSource: value || { id: '', name: '' },
    });
    validateForm();
  };

  const handleChangePosition = (value) => {
    setFormData({ ...formData, positionUser: value });
    validateForm();
  };

  const handleVerificationSuccess = (value) => {
    setIsPasswordValidated(true);
    setIsOpenEye(true);
  };

  useEffect(() => {
    if (isPasswordValidated) {
      setIsPasswordModalOpen(false);
    }
  }, [isPasswordValidated]);

  const handleEyeIconClick = () => {
    if (!isPasswordValidated) {
      setIsPasswordModalOpen(true);
    } else {
      setIsOpenEye(true);
    }

    if (isOpenEye) {
      setIsOpenEye(false);
    }
  };


  useEffect(() => {
    if (isEdit && editingIntegration) {
      const selectedSource = options.find(option => option.id.default === editingIntegration.provider || option.id.priority === editingIntegration.provider);
      setShowAdvancedConfig(editingIntegration.concat_url && editingIntegration.concat_url !== '');
      setFormData(prevFormData => ({
        ...prevFormData,
        positionUser: editingIntegration.candidature || { id: '', name: '' },
        recruitmentSource: selectedSource || { id: '', name: '' },
        concatUrl: editingIntegration.concat_url || '',
        ePreselecUrl: editingIntegration.login_url || '',
        username: editingIntegration.user || '',
        passwordUser: editingIntegration.password || '',
        generatedKeyword: editingIntegration.keyword || '',
      }));

      setInitialUser(editingIntegration.user || '');
      setInitialPassword(editingIntegration.password || '');
      if (editingIntegration.login_url) {
        let epreselectClient = editingIntegration.login_url;
        epreselectClient = epreselectClient.match(/https:\/\/(.*).admin.epreselec.com\/es\/vacantes/);
        epreselectClient = isArray(epreselectClient) && epreselectClient.length > 1 ? epreselectClient[1] : "";

        setValueUrl(epreselectClient);
      }
    } else {
      setValueUrl("");
    }
  }, [isEdit, editingIntegration]);

  const resetModalValues = () => {
    if (isOpen & !isEdit) {
      setFormData({
        positionUser: '',
        recruitmentSource: { id: '', name: '' },
        concatUrl: '',
        ePreselecUrl: '',
        username: '',
        passwordUser: '',
        generatedKeyword: '',
      });
      setIsFormValid(false);
      setIsDisabledButton(false);
      setIsPasswordValidated(false);
      setIsOpenEye(false);
      setShowAdvancedConfig(false);
    } else if (isEdit) {
      const selectedSource = options.find(option => {
        return option.id.default === editingIntegration.provider || option.id.priority === editingIntegration.provider
      });
      setFormData({
        positionUser: editingIntegration.candidature || { id: '', name: '' },
        recruitmentSource: selectedSource || { id: '', name: '' },
        concatUrl: editingIntegration.concat_url || '',
        ePreselecUrl: editingIntegration.login_url || '',
        username: initialUser,
        passwordUser: initialPassword,
        generatedKeyword: editingIntegration.keyword || '',
      });
      setIsFormValid(false);
      setIsDisabledButton(false);
      setIsPasswordValidated(false)
      setIsOpenEye(false);
    }
  };

  const handleGenerateKeyword = async (e) => {
    e.preventDefault();

    try {
      if (!keywordRequested) {
        setKeywordRequested(true);
        setLoading(true);
      }
      const rpaKeyword = await refetchKeyword();
      if (rpaKeyword && rpaKeyword.data && rpaKeyword.data.keyword) {
        setFormData({
          ...formData,
          generatedKeyword: rpaKeyword.data.keyword,
        });
      } else {
        console.error('La respuesta de la petición no contiene una keyword válida.');
      }
    } catch (error) {
      console.error('Error al generar la keyword:', error);
    } finally {
      setLoading(false);
    }
  }

  const mutationRecoverPassword = useMutation(recoverPassword);

  const handleSendPasswordEmail = async (e) => {
    e.preventDefault();

    try {
      await mutationRecoverPassword.mutateAsync();
      setAlert({ message: i18n.keywords_rpa.successSendPasswordEmail, severity: 'success' });
      setShowAlertPassword(true);
      setShowAlert(false);
    } catch (error) {
      setAlert({ message: i18n.keywords_rpa.errorSendPasswordEmail, severity: 'error' });
      setShowAlertPassword(true);
      setShowAlert(false);
      console.error('Error al enviar el email:', error);
    }
  };

  return (
    <>
      <Modal
        style={customStyles}
        className="modal-wrapper"
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={() => {
          handleClose();
          if (!isEdit) {
            resetModalValues();
          }
        }}
      >
        <ModalStyled>
          <ModalHeaderProcess title={isEdit ? i18n.keywords_rpa.modalTitleEditIntegration : i18n.keywords_rpa.modalTitleNewIntegration} closeClick={() => handleClose()} hasCloseIcon={true} />
          <div className="modal-body">
            <form autoComplete='off'>
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12}>
                  {isEdit && (
                    <Grid item xs={12} sm={12} className='container-keyword'>
                      <RegularButton className="button-custom" title={i18n.keywords_rpa.buttonGenerateKeyword} disabled={true} onClick={handleGenerateKeyword} />
                      <InputField
                        className={isEdit ? 'disabled-input-keyword' : "input-custom-keyword"}
                        value={editingIntegration.keyword}
                        disabled={true}
                      />
                    </Grid>
                  )}
                  <label>{i18n.keywords_rpa.labelPosition}</label>
                  <Autocomplete
                    className={isEdit ? 'disabled-input' : 'autocomplete-custom'}
                    value={formData.positionUser}
                    options={optionsPosition}
                    onChange={(event, value) => handleChangePosition(value)}
                    getOptionLabel={(option) => option && option.name ? option.name : ''}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={i18n.keywords_rpa.placeholderPosition} variant="outlined" />
                    )}
                    sx={{ width: '500px' }}
                    disabled={isEdit}
                  />
                  <label>{i18n.keywords_rpa.labelSource}</label>
                  <Autocomplete
                    className={isEdit ? 'disabled-input' : 'autocomplete-custom'}
                    value={formData.recruitmentSource}
                    options={options}
                    onChange={(event, value) => handleChangeSource(value)}
                    getOptionLabel={(option) => option.name}
                    defaultValue={[]}
                    renderInput={(params) => (
                      <TextField {...params} placeholder={i18n.keywords_rpa.placeholderSource} variant="outlined" />
                    )}
                    sx={{ width: '500px' }}
                    disabled={isEdit}
                  />
                  {formData.recruitmentSource?.name === 'ePreselec' && (
                    <>
                      <Tooltip title={i18n.keywords_rpa.urlRequirements} placement="right" arrow>
                        <label>{i18n.keywords_rpa.labelUrl}</label>
                      </Tooltip>
                      <div className="container-url">
                        <span>{i18n.keywords_rpa.prefixUrl}</span>
                        <InputField
                          placeholder={i18n.keywords_rpa.placeholderUrl}
                          value={valueUrl}
                          onChange={(e) => handleInputChange(e, "ePreselecUrl")}
                          className={isEdit ? 'disabled-input' : ''}
                          disabled={isEdit}
                        />
                        <span>{i18n.keywords_rpa.sufixUrl}</span>
                      </div>
                    </>
                  )}

                  <label>{i18n.keywords_rpa.labelUser}</label>
                  <InputField placeholder={i18n.keywords_rpa.placeholderUser}
                    value={formData.username}
                    onChange={(e) => handleInputChange(e, "username")}
                    type='text'
                  />

                  {isEdit &&
                    <>
                      <label>{i18n.keywords_rpa.labelPassword}</label>
                      <InputField
                        name='password'
                        placeholder={i18n.keywords_rpa.placeholderPassword}
                        value={formData.passwordUser}
                        onChange={(e) => handleInputChange(e, "passwordUser")}
                        type={isEdit && !isOpenEye ? 'password' : 'text'}
                        onEyeIconClick={handleEyeIconClick}
                        showEyeIcon={true}
                        showIcon
                      />
                    </>
                  }

                  {!isEdit &&
                    <>
                      <label>{i18n.keywords_rpa.labelPassword}</label>
                      <InputField
                        name='password'
                        placeholder={i18n.keywords_rpa.placeholderPassword}
                        value={formData.passwordUser}
                        onChange={(e) => handleInputChange(e, "passwordUser")}
                        type='text'
                        onEyeIconClick={handleEyeIconClick}
                        showEyeIcon={false}
                        showIcon
                      />
                    </>
                  }

                  {isEdit && (
                    <>
                      <div className="container-password">
                        <RegularButtonOutline
                          title={i18n.keywords_rpa.recoverPasswordEmail}
                          className='button-outline-custom'
                          variant="outlined" onClick={handleSendPasswordEmail} />
                        {mutationRecoverPassword.isLoading && <CircularProgress />}
                        {showAlertPassword && !showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                      </div>
                    </>
                  )}
                  <div>
                  <label>{i18n.keywords_rpa.labelAdvancedConfig}</label>
                  <Switch
                    style={{display: "block"}}
                    checked={showAdvancedConfig}
                    onChange={(e) => {
                      setShowAdvancedConfig(e.target.checked);
                      if (!e.target.checked) setFormData({...formData, concatUrl: ''});
                    }}
                    name="advancedConfig"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                  {showAdvancedConfig ? <div>
                      <label>{i18n.keywords_rpa.labelConcatUrl}</label>
                      <InputField placeholder={i18n.keywords_rpa.placeholderConcatUrl}
                                  value={formData.concatUrl}
                                  onChange={(e) => handleInputChange(e, "concatUrl")}
                                  type="text"/>
                    </div>
                    : <></>}
                </div>

                </Grid>

                {<>
                  {isFormValid && <Grid item xs={12} sm={12} className='alert-custom'>
                    <CustomAlert severity="info" message={i18n.keywords_rpa.messageGenerateKeyword} />
                  </Grid>}
                  <Grid item xs={12} sm={12} className='container-keyword'>
                    <RegularButton className="button-custom" title={i18n.keywords_rpa.buttonGenerateKeyword}
                                   disabled={!isFormValid} onClick={handleGenerateKeyword} />
                    {loading && <CircularProgress />}
                    {formData.generatedKeyword && (
                      <InputField
                        className="input-custom-keyword"
                        value={formData.generatedKeyword || ''}
                        disabled={true}
                      />
                    )}
                  </Grid>
                </>}
              </Grid>

              <div className="button-wrapper">
                {showAlert && <CustomAlert severity={alert.severity} message={alert.message} />}
                <RegularButtonOutline title={i18n.keywords_rpa.buttonCancel} handleOnClick={() => {
                  handleClose();
                  if (!isEdit) {
                    resetModalValues();
                  }
                }} />
                <RegularButton className="button-custom" title={isEdit ? i18n.keywords_rpa.buttonUpdateIntegration : i18n.keywords_rpa.buttonSaveIntegration} onClick={handleNewIntegration} />
              </div>
            </form>
          </div>

          {confirmIntegration && (
            <ModalConfirmIntegration
              open={confirmIntegration}
              handleClose={() => setConfirmIntegration(false)}
              formData={formData}
              isEdit={isEdit}
              handleCreateIntegration={createIntegration}
              handleUpdateIntegration={updateIntegration}
              onDataUpdate={onDataUpdate}
              isDisabledButton={isDisabledButton}
              setIsDisabledButton={setIsDisabledButton}
            />
          )}

          {isPasswordModalOpen && (
            <ModalConfirmLogin
              open={isPasswordModalOpen}
              handleClose={() => setIsPasswordModalOpen(false)}
              onVerificationSuccess={handleVerificationSuccess}
              setIsPasswordValidated={setIsPasswordValidated}
            />
          )}
        </ModalStyled>
      </Modal>
    </>
  )
}

const enharce = compose(withI18n)

export default enharce(ModalNewIntegration)