import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from './components/system'
import * as serviceWorker from './serviceWorker'
import { TOKEN_KEY } from './config/constants'

window.forceLogout = () =>{
    //localStorage.setItem('isLogout', '1');
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('valid_token');
    window.location.pathname = "/login";
}
if(window.location.pathname === "/logout"){
    window.forceLogout();
}


ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
// Force deploy
