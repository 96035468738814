/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'
import MaterialTable from 'material-table'
import {
  MTImage,
  MTPersonalData,
  MTStatusTextColor,
  MTCitationStatusTextColor,
  MTText,
  MTToolbar,
} from '../../atoms'
import { ModalAnnouncementQuery, ModalChangeStatus } from '../../molecules'
import {ExportButton, PlanningTableStyled} from './styled'
import i18n from '../../../assets/i18n'
import * as api from '../../../api'
import {getRange, postOrPatchCitations} from '../../../api'
import Tag from "../../atoms/tag";
import {removeHTML} from "../../../utils/url";
import {MODULES, QUERY_KEYS} from "../../../config/constants";
import ModalChangeTags from "../../molecules/modal-change-tags";
import ModalRequireDoc from "../../molecules/modal-require-doc";
import {StatusType} from "../../../models";
import autoStatusDomains from "../../../utils/auto-status-domains";
import useStatusTypes from "../../../api/queries/useStatusTypes";
import {useMutation} from "@tanstack/react-query";
import Button from "@material-ui/core/Button";
import { Chip } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { useFunnelStatusTypes } from '../../../api/queries/useFunnelStatusTypes'
import { invalidateQueriesByKeys } from '../../../api/mutations/mutationCallbacks'
import { iso8601Format } from '../../../utils/date'
import { FeatureGuard } from '../../../utils/guard'

const getReadClass = rowData => (_.get(rowData, 'candidature.was_read', false) ? 'was_read' : 'not_read')

const PlanningTable = (
  {
    initPlanning,
    filters,
    page,
    setPage,
    pageSize,
    setPageSize,
    sort,
    setSort,
    location,
    history,
    setFilters,
    setRowsSelected,
    postFavorites,
    module,
    changeStatus,
    reportFields,
    total,
    data,
    isFetching,
    client,
    auth,
    fetchCitationsData,
    rowsSelected,
    inboxModule,
    tagsTypes,
    fetchTagsTypes,
    changeTags,
    requireDocumentation,
    user_email,
    //responsibles
  }) => {
  const theme = useTheme();

  //Variables and States
  const tableRef = useRef();
  const { data: statusTypes } = useStatusTypes(module, user_email);
  const { data: statusFunnelTypes } = useFunnelStatusTypes(module);

  const [selectedRows, setSelectedRows] = useState([]);
  const [openChangeTagsModal, setOpenChangeTagsModal] = useState(false)
  const [announcementModal, setAnnouncementModal] = useState(false)
  const [announcement, setAnnouncement] = useState(null)
  const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
  const [requireDocModal, setRequireDocModal] = useState(false);
  const [requireDoc, setRequireDoc] = useState(null);
  const [statusTypesByUser, setStatusTypesByUser] = useState([])
  //const mutationDetailMeetings = useMutation(api.getMeetingDetails);
  const mutationUpdateStatesSelectionFunnel = useMutation(api.updateNewStatesSelectionFunnel);

  const userTimeZone = moment.tz.guess();
  // const userTimeZone = 'Europe/London';

  useEffect(() => {
    if (statusTypes) {
      let showOnlyManualStatus = true;
      if (user_email) {
        autoStatusDomains().forEach(item => {
          if (user_email.includes(item)) {
            showOnlyManualStatus = false;
          }
        });
      }
      if (showOnlyManualStatus) {
        setStatusTypesByUser(statusTypes.filter(item => item.automatic === false));
      } else {
        setStatusTypesByUser(statusTypes);
      }
    }
    fetchTagsTypes();
  }, [statusTypes]);

  useEffect(() => {
    fetchCitationsData(filters, getRange(page, pageSize), sort)
  }, [filters, page, pageSize, sort]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateDataQuery();
  }, [data])// eslint-disable-line react-hooks/exhaustive-deps


  //Functions

  const updateData = () => {
    if (inboxModule !== module) {
      initPlanning( filters, sort, api.getRange(page, pageSize) );
    } else {
      fetchCitationsData( filters, getRange( page, pageSize ), sort );
    }
  }

  const updateDataQueryForce = () => {
    setFilters( _.cloneDeep( filters ) );
  }

  const handleChangePage = ( page ) => {
    setPage( page );
  }

  const handleChangeRowsPerPage = ( perPage ) => {
    setPage( 0 );
    setPageSize( perPage );
  }

  const handleOrderChange = ( column, orderDirection ) => {
    setPage( 0 );
    setSort( orderDirection ? [column.field, orderDirection] : ["",""] );
  }

  const updateDataQuery = () => tableRef && tableRef.current && tableRef.current.onQueryChange()

  const getQueryParams = () => {
    return {
      filters: _.cloneDeep(filters),
      range: getRange(page, pageSize),
      sort
    }
  }

  const checkRow = ( event ) => {
    setSelectedRows( event );
    setRowsSelected( event );
  }
    //Add selected rows to favourites
  const postFavorite = ( selectedRows, isFavorite ) => {

    if ( _.size(selectedRows) > 0 ) {
      const ids = _.map( selectedRows, 'candidature.id' );
      postFavorites( ids, isFavorite, module ).then( (updateDataQueryForce) );
    }

  }

  const onPrintSelected = () => {
    const printPath = `/profiles/${ module }/` + _.map( selectedRows, 'candidature.id' ).join( '-' ) + ( _.size(selectedRows) === 1 ? '-' : '' );
    history.push( printPath );
  }

  const clearSelectedRows = () => {
    setSelectedRows([]);
    tableRef.current && tableRef.current.onAllSelected( false );
    setRowsSelected([]);
  }

  const onSubmitChangeStatus = ( status, date ) => {
    setOpenChangeStatusModal( false );
    if ( _.size(selectedRows) > 0 ) {
      const ids = _.map( selectedRows, 'candidature.id' )
      changeStatus( ids, status, date, module ).then( updateDataQueryForce )

      clearSelectedRows()
    }

  }

  const refetchInboxesQueriesBySection = (extraQueries = []) => {
    let queriesToInvalidate = [`${QUERY_KEYS.INBOX}`,`${QUERY_KEYS.FAVORITES}`, `${QUERY_KEYS.SAVED_SEARCHES_ITEM}`];
    queriesToInvalidate = queriesToInvalidate.concat(extraQueries);

    invalidateQueriesByKeys(module, queriesToInvalidate);
    updateData();
  }

  const onSubmitChangeFunnelStatus = (status) => {
    if (_.size(selectedRows) > 0) {
      const ids = _.map(selectedRows, (citation) => citation.candidature.id);
      let data = [];
      let queriesToRefetch = [];
      let queriesToInvalidate = [];
      ids.forEach(id => {
        data.push({id: id, status_id: status.id, date: iso8601Format()});
        queriesToRefetch.push(`${QUERY_KEYS.PROFILE}:${id}`)
        queriesToInvalidate.push(`${QUERY_KEYS.OBSERVATIONS}:${id}`)
      });

      mutationUpdateStatesSelectionFunnel.mutateAsync(data, {
        onSuccess: () => {
          refetchInboxesQueriesBySection(queriesToRefetch);
          invalidateQueriesByKeys(module, queriesToInvalidate);
        },
      });
      clearRowsSelected();
    }
  }

  const onSubmitChangeTags = (tags) => {
    setOpenChangeTagsModal(false)
    if (_.size(rowsSelected) > 0) {
      const ids = _.map(rowsSelected, 'candidature.id')
      const queryParams = getQueryParams();
      changeTags(ids, tags, queryParams, module, filters ?? null).then(updateDataQueryForce)
      clearRowsSelected()
    }
  }

  const onSubmitRequireDoc = (data) => {
    if (requireDoc && _.size(requireDoc.rowsSelected) > 0) {
      const ids = _.map(requireDoc.rowsSelected, 'candidature.id')
      requireDocumentation(ids, data).then(() => {
        requireDoc.updateQuery(true)
        setRequireDoc(false)
      })
      requireDoc.clearRowsSelected()
    }
  }


  const onSubmitAnnouncement = (data) => {

    // if (announcement && _.size(announcement.rowsSelected) > 0) {
    if (announcement) {

      let candidatureIds;
      if(!announcement.rowsSelected){
        candidatureIds = announcement.candidature_ids ? announcement.candidature_ids.split(',') : [announcement.candidature.id]
        // candidatureIds = [announcement.candidature_ids ? announcement.candidature_ids.split(',') :announcement.candidature.id]
      } else {
        let filteredAnnouncement = announcement.rowsSelected.filter(item => ![StatusType.HIRED, StatusType.DISCARDED, StatusType.DISCARDED_PHASE_2].includes(item.candidature.state.id));
        candidatureIds = _.map(filteredAnnouncement, 'candidature.id');
      }

      data.create = false;
      postOrPatchCitations({data, ids: candidatureIds}).then(updateDataQueryForce);
      if(announcement.rowsSelected){
        announcement.clearRowsSelected();
      }

    }
  }

  // const handleSubmitModalPlaning = ( filters ) => {
  //   setFilters( filters );
  //   setPage( 0 );
  // }

  const getInitials = fullName => {

    function getInitial(name){
      return name && name.length ? name[0].toUpperCase() : '';
    }

    const fullNameSplit = fullName ? fullName.split(' ') : false;
    if (fullNameSplit && fullNameSplit.length > 1) {
      return getInitial(fullNameSplit[0]) + getInitial(fullNameSplit[1]);
    }
    return '';
  }

  // const handleClickResetFilters = () => {
  //   filtersClear();
  // }

  const onItemClick = (id) => {
    _.find(toolbarOptions, { id }).onClick({
      rowsSelected,
      updateQuery: updateData,
      clearRowsSelected
    })
  }

  const clearRowsSelected = () => {
    setRowsSelected([])
    tableRef.current && tableRef.current.onAllSelected(false)
  }

  // const getTotalrowsSelected = () => {
  //   return rowsSelected ? _.map(rowsSelected, (item) => item.id).length : 0;
  // }

  const onRowClick = ( e, row ) => {
    if (row.people === 1) {
      localStorage.setItem( 'backLocation', location.pathname + '?keepFilters=1' );
      // TODO: soporte para la ficha de la persona / job_offer
      history.push( `/${module}/planning/profile/${row.candidature.id || row.person_id}` );
    }else {
      return;
    }
  }

  // const handleCloseViewModal = () => {
  //   updateDataQueryForce();
  // }

  const toolbarOptions = [
    // {
    //   id: 'quoteCall',
    //   label: i18n.peopleTable.quoteCall,
    //   onClick: () => { setOpenAnnouncementModal( true ) }
    // },
    {
      id: 'changeState',
      label: i18n.peopleTable.changeState,
      onClick: () => {
        setOpenChangeStatusModal(true)
      }
    },
    {
      id: 'markFavorite',
      label: i18n.peopleTable.markFavorite,
      onClick: () => {
        postFavorite( selectedRows, true )
        clearSelectedRows()
      }
    },
    {
      id: 'removeFavorites',
      label: i18n.processesTable.removeFavorites,
      onClick: () => {
        postFavorite( selectedRows, false )
        clearSelectedRows()
      }
    },
    {
      id: 'print',
      label: i18n.peopleTable.print,
      onClick: () => {
        onPrintSelected()
      }
    },
    module === MODULES.ID_TALENT_ACQUISITION ? {
      id: 'changeStatus',
      label: i18n.processesTable.changeStatus,
      onClick: () => {
        setOpenChangeStatusModal(true)
      }
    } : null,
    // module === MODULES.ID_TALENT_ACQUISITION ? {
    //   id: 'announcement',
    //   label: i18n.peopleTable.editQuoteCall,
    //   onClick: (props) => {
    //     setAnnouncementModal(true);
    //     setAnnouncement(props);
    //   }
    // } : null,
    //
    // module === MODULES.ID_TALENT_ACQUISITION ? {
    //   id: 'requireDoc',
    //   label: i18n.peopleTable.requireDoc,
    //   onClick: ({ ...props }) => {
    //
    //   }
    //   onClick: () => {setOpenRequireDocModal( true )
    //     this.handleRequireDoc( this.props );
    //   }
    // } : null,
    module === MODULES.ID_TALENT_ACQUISITION ? {
      id: 'requireDoc',
      label: i18n.peopleTable.requireDoc,
      onClick: ({...props}) => {
        setRequireDocModal(true)
        setRequireDoc(props)
      }
    } : null,
    module !== MODULES.ID_EXIT_INTERVIEW && tagsTypes && tagsTypes.length > 0 ? {
      id: 'changeTags',
      label: i18n.processesTable.changeTags,
      onClick: () => {
        setOpenChangeTagsModal(true)
      }
    } : null,
  ];

  const getExportSort = ( sort ) => {
    let newSort = [...sort];
    newSort[0] = `citations.${sort[0]}`;

    return newSort;
  }

  const getExportFilters = ( filters ) => {
    let newFilters = {}
    if ( !filters ) return {}

    _.map(Object.keys(filters), (key) => {

      let keyToSend = `citations.${key}`
      if ( key.includes('candidature.') ) {
        keyToSend = key.replace('candidature.', '')
      }

      return newFilters[keyToSend] = filters[key]
    })
    return newFilters
  }

  const getExportFiltersInboxFormat = () => {
    return { ...getExportFilters( filters ), isCitation: true }
  }

  //SubComponents

  const MTDate = ( rowData ) => {
    const originalDate = moment.tz(rowData.date.date, "Europe/Dublin");
    const timezonedDate = originalDate.clone().tz(userTimeZone);

    return <MTText className={getReadClass(rowData)} value={timezonedDate.format('DD/MM/YYYY HH:mm')} />
  }

  const MTTag = ( rowData ) => (
    <>
      <div style={{float: 'right'}}>{
        rowData.candidature.vars && _.map(rowData.candidature.vars.tags, tag => {
          return <Tag tagTypesReactQuery={tagsTypes} tag={tag} fontSize={'1em'} icon={''} shortVersion/>
        })
      }</div>
    </>
  );

  const MTCitationDate = ( rowData ) => {
    const originalDate = moment.tz(rowData.citationDate.date, rowData.citationDate.timezone);
    const timezonedDate = originalDate.clone().tz(userTimeZone);

    return (
      <MTText
        className={getReadClass(rowData)}
        value={timezonedDate.format('DD/MM/YYYY HH:mm')}
      />
    )
  }

  const MTAttendees = ( rowData ) => {
    let attendees = '';
    rowData.attendees && rowData.attendees.forEach(attendee => {
        attendees += attendee.emailAddress && ', ' + attendee.emailAddress.address ;
    });

    let attendeesText = rowData.names + "" + attendees;

    if (attendeesText.length > 20) {
      attendeesText = attendeesText.substring(0, 20) + ' ...';
    }
    return <MTText title={rowData.names + "" + attendees} onClick={(event) => {handleViewCitation(event, rowData)} } style={{'text-overflow': 'ellipsis'}} className={getReadClass(rowData)} value={ attendeesText }/>
  };

  const handleViewCitation = (event, row) => {
    event.stopPropagation();
    setAnnouncement(row);
    setAnnouncementModal(true);
  }

  const MTStatus = ( rowData ) => {
    if(parseInt(rowData.people) > 1){
      return <Button ><img onClick={(event) => {handleViewCitation(event, rowData)} } alt={i18n.planningTable.view} height={"20px"} width={"20px"} src={"https://hrbf-public.s3.eu-west-1.amazonaws.com/assets/more-info-icon.svg"}/></Button>
    }

    let attendee = rowData.attendees && rowData.attendees.filter(at => at.type === 'required')[0];
    const candidateStatus = parseInt(rowData.state);
    attendee = {
      'id': attendee && candidateStatus === 19 ? attendee.status.response : candidateStatus
    }

    return <MTStatusTextColor onClick={(event) => {handleViewCitation(event, rowData)} } className={getReadClass(rowData)} value={attendee} />;
  }

  const MTCitationStatus = (rowData) => {
    if(parseInt(rowData.people) > 1){
       return <Button ><img onClick={(event) => {handleViewCitation(event, rowData)} } alt={i18n.planningTable.view} height={"20px"} width={"20px"} src={"https://hrbf-public.s3.eu-west-1.amazonaws.com/assets/more-info-icon.svg"}/></Button>
    }

    let attendee = rowData.attendees && rowData.attendees.filter(at => at.type === 'required')[0];
    const candidateStatus = parseInt(rowData.state);
    attendee = {
      'id': attendee && candidateStatus === 19 ? attendee.status.response : candidateStatus
    }

    return <MTCitationStatusTextColor
      onClick={(event) => { handleViewCitation(event, rowData) }}
      className={getReadClass(rowData)}
      value={rowData.status}
    />
  };

  const MTPersonalDataPlanning = ( rowData ) => {
    return parseInt(rowData.people) > 1 ?
        <MTText style={{'text-overflow': 'ellipsis', 'text-align': 'center'}} className={getReadClass(rowData)} value={i18n.planningTable.groupal}/> :
        <MTPersonalData nombre={ rowData.names} />;
  };
  const MTImagePlanning = ( rowData ) => {
    let image = "https://hrbf-public.s3.eu-west-1.amazonaws.com/assets/Groupal-icon-citation.svg"

    return <>
            <div style={{marginBottom: '5px', display: 'inline-block'}}>
              <MTImage foto={rowData.people > 1 ? image : _.get(rowData, 'photo')}
                       avoidLink={true}
                       inicials={getInitials(rowData.person_name + ' ' + rowData.person_surname)} />
            </div>
          </>
  };

  /*const getOrganizerNameById = (id) =>{
    if(!responsibles) return "";
    const organizer = responsibles.find(r => r.id == id);
    return organizer ? organizer.name + " " + organizer.surname : "";
  }*/

  const MTOrganizer = ( rowData ) => ( <MTText className={getReadClass(rowData)} value={`${ _.get(rowData, 'username' )}`}  /> );
  const handleClickUrlMeeting = (event, rowData) => {
    event.stopPropagation();
    window.open(rowData.link_meeting, '_blank');
  }
  const MTLocation = ( rowData ) => {
    if (rowData.hasOwnProperty('link_meeting') && !_.isEmpty(rowData.link_meeting)) {
      if (rowData.link_meeting.includes('google.com')) {
        return (
            <img
                alt="Google"
                height="30px"
                width="30px"
                onClick={(event) => handleClickUrlMeeting(event, rowData)}
                src="https://hrbf-public.s3.eu-west-1.amazonaws.com/assets/Google_Meet_Icon.svg"
            />
        );
      } else if (rowData.link_meeting.includes('teams.microsoft.com')) {
        return (
            <img
                alt="Teams"
                height="30px"
                width="30px"
                onClick={(event) => handleClickUrlMeeting(event, rowData)}
                src="https://hrbf-public.s3.eu-west-1.amazonaws.com/assets/Microsoft_Office_Teams_Icon.svg"
            />
        );
      }
    }



    let locationText = removeHTML(rowData.location);
    if (locationText.length > 20) {
      locationText = locationText.substring(0, 20) + ' ...';
    }
    return <MTText style={{textAlign: 'center'}} className={getReadClass(rowData)} title={rowData.location} value={locationText} /> ;
  }

  const MTAvailability = ( rowData ) => ( <MTText className={getReadClass(rowData)} value={_.get( rowData, 'availability_label' )} /> );

  const MTReason = (rowData) => {
    let value;
    if (_.get(rowData, 'state') === "6") {
      value = _.get(rowData, 'reason');
    } else {
      value = _.get(rowData, 'not_interested_label');
    }
    return <MTText className={getReadClass(rowData)} value={value} />;
  };

  const MTCandidatureSelectionPhase = (rowData) => {
    const value = rowData.candidature.selectionStatuses?.phase?.name ?? rowData.jobOfferApplication?.selectionStatus?.phase?.name ?? null;
    return value && (
      <Chip
        label={value}
        style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText,
          minWidth: '80px', height: '25px', fontSize: '13px' }}
      />
    );
  };

  const MTCandidatureSelectionStatus = (rowData) => {
    const color = rowData.candidature.selectionStatuses?.color ??
                  rowData.jobOfferApplication?.selectionStatus?.color ??
                  '#d3d3d3';

    const value = rowData.candidature.selectionStatuses?.name ??
                  rowData.jobOfferApplication?.selectionStatus?.name ??
                  null;

    return value && (
      <Chip
        label={value}
        style={{ backgroundColor: color, minWidth: '80px', height: '25px', fontSize: '13px' }}
      />
    );
  };

  //Table Utilities

  let columns = [
    { order: 1, sorting: false, title: '', field: 'tags', render: MTTag, headerStyle: {'padding': '16px 16px 16px 13px'}, cellStyle: { textAlign: 'center' },  },
    { order: 2, field: 'image', title: '', render: MTImagePlanning},
    { order: 3, title: i18n.planningTable.personalData, field: 'candidature.nombre', render: MTPersonalDataPlanning, cellStyle: { width: 200 } },
    { order: 4, title: i18n.planningTable.date, field: 'date', render: MTDate },
    { order: 5, title: i18n.planningTable.citation, field: 'citationDate', render: MTCitationDate },
    { order: 6, title: i18n.planningTable.location, field: 'location', render: MTLocation },
    { order: 7, title: i18n.planningTable.technical, field: 'user', render: MTOrganizer },
    { order: 8, sorting: false, title: i18n.planningTable.atendees, field: 'attendees', render: MTAttendees },
    { order: 12, title: i18n.planningTable.availability, field: 'availability', render: MTAvailability },
    { order: 13, title: i18n.planningTable.reason, field: 'notInterested', render: MTReason },
  ];

  if (FeatureGuard.canAccessToFunnelStatus()) { // Client has PHASES & STATUS feature
    columns = columns.concat([
      { order: 9, title: i18n.planningTable.phase, field: 'candidature.selectionStatuses.phase', render: MTCandidatureSelectionPhase },
      { order: 10, title: i18n.planningTable.statusPhase, field: 'candidature.selectionStatuses', render: MTCandidatureSelectionStatus },
      { order: 11, title: i18n.planningTable.citationStatus, field: 'status', render: MTCitationStatus },
    ]);

  } else {
    columns.push({ order: 9, title: i18n.planningTable.status, field: 'candidature.state', render: MTStatus });
  }

  const orderColumnsToShow = (columns) => {
    return columns.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  }

  const tableComponents = {
    Container: props => <div { ...props } />,
    Toolbar: () => (
      <MTToolbar
        module={module}
        onItemClick={onItemClick}
        onSubmitChangeStatus={onSubmitChangeStatus}
        statusTypes={statusTypesByUser}
        onSubmitChangeFunnelStatus={onSubmitChangeFunnelStatus}
        funnelStatusTypes={statusFunnelTypes?.data ?? []}
        options={toolbarOptions} disabled={_.isEmpty(selectedRows)}
      >
        <ExportButton
          module={ module }
          fields={ reportFields }
          filters={ getExportFiltersInboxFormat() }
          sort={(!sort || !sort[0])?['citations.citationDate', 'desc']:getExportSort(sort)}
          ids={ _.map(selectedRows, (item) => _.get(item, 'candidature.id')) }
          totalRows={ total }
        />
      </MTToolbar>
    )
  };

  const dataQuery = async () => {
    try {
      if ( (!data || data.data.length <= 0) || (!columns || columns.length <= 0) ){
        return { data: [], page: 0, totalCount: 0 }
      } else {
        return { data: data.data, page, totalCount: data.total }
      }

    } catch (e) {
      return { data: [], page: 0, totalCount: 0 }
    }
  }

  const tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    selection: true,
    cellStyle: { textAlign: 'center' },
    pageSize: pageSize,
    pageSizeOptions: [20, 50, 100, 200],
  }

  return (
    <PlanningTableStyled>
      {data &&
        <MaterialTable
          tableRef={tableRef}
          columns={orderColumnsToShow(columns)}
          data={dataQuery}
          isLoading={isFetching}
          options={tableOptions}
          onSelectionChange={checkRow}
          components={tableComponents}
          localization={i18n.tableLocalization}
          onRowClick={onRowClick}
          draggable={false}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onOrderChange={(orderedColumnId, orderDirection) => handleOrderChange(columns[orderedColumnId], orderDirection)}
        />
      }
      {module === MODULES.ID_TALENT_ACQUISITION && <ModalChangeStatus
        open={openChangeStatusModal}
        handleClose={() => setOpenChangeStatusModal(false)}
        selectedRows={selectedRows}
        onSubmit={onSubmitChangeStatus}
      />}
      {module === MODULES.ID_TALENT_ACQUISITION && <ModalAnnouncementQuery
        open={announcementModal}
        handleClose={() => setAnnouncementModal(false)}
        onSubmit={onSubmitAnnouncement}
        sms={client ? client.sms_available : false}
        self={auth ? auth : null}
        isEdit={true}
        data={announcement}
      />}
      {module === MODULES.ID_TALENT_ACQUISITION && tagsTypes && tagsTypes.length > 0 &&
        <ModalChangeTags
          open={openChangeTagsModal}
          handleClose={() => setOpenChangeTagsModal(false)}
          rowsSelected={rowsSelected}
          onSubmit={onSubmitChangeTags}
        />}
      {module === MODULES.ID_TALENT_ACQUISITION && <ModalRequireDoc
        open={requireDocModal}
        handleClose={() => setRequireDocModal(false)}
        onSubmit={onSubmitRequireDoc}
      />}
    </PlanningTableStyled>
  )


}

PlanningTable.propTypes = {
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool
}

export default PlanningTable;
