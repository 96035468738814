import React from 'react'
import { TemplateRequestTable } from '../../../organism'

import i18n from '../../../../assets/i18n'
import { useVacancyRequestManagerPending } from '../../../../api/queries/useVacancyRequestManager'

const Pending = ({ rowSelected, setRowSelected, startDate, endDate, type }) => {
  const [page, setPage] = React.useState(0)
  const [perPage, setPerPage] = React.useState(10)
  const [range, setRange] = React.useState([0, 9])
  const [sort, setSort] = React.useState(['createdAt', 'desc'])

  const { data, refetch, isLoading } = useVacancyRequestManagerPending(range, sort, startDate, endDate)

  const getRange = (pageVal, perPageVal) => {
    return [pageVal * perPageVal, perPageVal * (pageVal + 1) - 1]
  }

  React.useEffect(() => {
    refetch()
  }, [])

  React.useEffect(() => {
    setRange(getRange(page, perPage))
  }, [page, perPage])

  return (
    <>
      <TemplateRequestTable
        data={data?.data}
        loading={isLoading}
        total={data?.total}
        i18n={i18n}
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
        range={range}
        setRange={setRange}
        sort={sort}
        setSort={setSort}
        showOpenProcesses={false}
        type={type}
      />
    </>
  )
}

export default Pending
