import React from 'react'
import { PageTitle } from '../../atoms'
import { Grid } from '@material-ui/core'
import UserManualStyled from './styled'

const UserManual = ({ i18n, self }) => {

  const getSrcByLang = (lang) => {
    switch (lang) {
      case 'en':
        return "https://scribehow.com/page/Users_guide__ClRp-3tKRJOCcCwYrsEMJA";
      default:
        return "https://scribehow.com/page/Manual_usuario_TA__9hd2WZR3QcimqFPmuh5x5g";
    }
  }

  return (
    <UserManualStyled>
      <Grid container className="title-container">
        <Grid item xs={12} sm={6} className="title">
          <PageTitle title={i18n.userManual.title}/>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        {self && <iframe
            className="iframe-container"
            title="Manual de uso ATS"
            src={getSrcByLang(self.lang)}>
          </iframe>}
      </Grid>
    </UserManualStyled>
  )
}

export default UserManual
