import {QueryClient} from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 1800000, // 30 mins
      cacheTime: 1800000, // 30 mins
      notifyOnChangePropsExclusions: ['isStale'],
      refetchOnWindowFocus: false
    }
  }
});

export default queryClient;
