import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { TemplateRequestManagerStyled } from './styled'
import { Grid, Switch, Typography } from '@material-ui/core'
import i18n from '../../../assets/i18n'
import { Box } from '@material-ui/core'
import moment from 'moment'
import DateRangePiker from '../../molecules/date-range-picker'
import Divider from '@material-ui/core/Divider'

import Pending from './components/Pending'
import Reviewed from './components/Reviewed'
import { useAppContext } from '../../../context/appContext'
import { SECTION_KEYS } from '../../../config/constants'

const useStyles = makeStyles({
  switchBase: {
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.4)'
    }
  }
})

const ValidatorTemplateRequest = () => {
  const classes = useStyles()
  const { filters: contextFilters, setFilters: setContextFilters } = useAppContext()

  const getContextShowPendingProcesses = () => {
    return contextFilters[SECTION_KEYS.VACANCY_REQUESTS]?.validationShowPendingProcesses ?? true
  }

  const setContextShowPendingProcesses = (showPendingProcesses) => {
    let tempFilters = contextFilters;
    tempFilters[SECTION_KEYS.VACANCY_REQUESTS].validationShowPendingProcesses = showPendingProcesses;

    setContextFilters(tempFilters);
  }

  const [startDate, setStartDate] = useState(moment().subtract(15, 'days'))
  const [endDate, setEndDate] = useState(moment())
  const [rowSelected, setRowSelected] = React.useState([])
  const [focused, setFocused] = useState(null)

  const [stateProcess, setStateProcess] = useState(getContextShowPendingProcesses())

  const handleStartDateChange = date => {
    setStartDate(date)
  }

  const handleEndDateChange = date => {
    setEndDate(date)
  }

  const handleChangeStateProcess = event => {
    setStateProcess(event.target.checked)
    setContextShowPendingProcesses(event.target.checked)
  }

  return (
    <TemplateRequestManagerStyled>
      <Grid container className="title-container">
        <Grid item className="sectionSecond" xs={12} sm={12}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box mb={2}>
              <Typography
                variant="h1"
                style={{ color: '#000000', display: 'inline', fontSize: '28px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '1.167' }}
              >
                {i18n.templateRequestManager.titleSectionValidator}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-end', placeSelf: 'flex-end', gridGap: '15px' }}></Box>
          </Box>
          <Divider variant="fullWidth" />
          <Box mb={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <h5>{i18n.templateRequestManager.descriptionSectionValidator}</h5>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', justifyContent: 'space-between', alignItems: 'center', gridGap: '20px' }}>
            <DateRangePiker
              startDate={startDate}
              onStartDateChange={handleStartDateChange}
              onEndDateChange={handleEndDateChange}
              endDate={endDate}
              onFocusChange={({ focused }) => setFocused(focused)}
              focused={focused}
              showButton={false}
            />

            <Typography component="div">
              <Box component="label" display="flex" alignItems="center" spacing={1}>
                <Box>{i18n.templateRequestManager.reviewed}</Box>
                <Box>
                  <Switch checked={stateProcess} onChange={handleChangeStateProcess} classes={{ switchBase: classes.switchBase }} />
                </Box>
                <Box>{i18n.templateRequestManager.pendingValidation}</Box>
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          {stateProcess ? (
            <Pending startDate={startDate} endDate={endDate} rowSelected={rowSelected} setRowSelected={setRowSelected} type="pending" />
          ) : (
            <Reviewed startDate={startDate} endDate={endDate} rowSelected={rowSelected} setRowSelected={setRowSelected} type="reviewed" />
          )}
        </Grid>
      </Grid>
    </TemplateRequestManagerStyled>
  )
}

export default ValidatorTemplateRequest
