import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import MaterialTable from 'material-table'
import { Box, Chip, IconButton } from '@material-ui/core'
import { useMutation, useQuery } from '@tanstack/react-query'
import * as XLSX from 'xlsx'

import * as api from '../../../api/index'
import queryClient from '../../../api/queryClient'
import { useAppContext } from '../../../context/appContext'
import { mutationFavoritesCallback } from '../../../api/mutations/mutationCallbacks'

import { ButtonWithIcon, FavouriteIcon, ScheduleIcon, FileIcon, MTImage, MTText, ProfileAvatar, AvatarName, AvatarMatch } from '../../atoms'
import { ModalAnnouncementQuery, ModalRequireDocQuery, SelectChangeStatusTemplateRequest } from '../../molecules'

import { ALERT_LEVEL, QUERY_KEYS, SECTION_KEYS, MODULES } from '../../../config/constants'
import i18n from '../../../assets/i18n'

import { TemplateRequestTableStyled } from './styled'
import { get } from '../../../api/utils'

const ToolbarTable = ({ rowSelected, client, vacancy, setRowSelected, tableRef, data, tableColumns }) => {
  const { createAlert } = useAppContext()

  const [announcementModal, setAnnouncementModal] = React.useState(false)
  const [requireDocModal, setRequireDocModal] = React.useState(false)
  const [funnelStatusTypesByUser, setFunnelStatusTypesByUser] = React.useState([])
  const [valueStatus, setValueStatus] = React.useState(null)

  const mutationPostState = useMutation(api.postState_query)

  async function getFunnelStatusTypes() {
    return await get(`api/module/${module}/phases/status`)
  }

  const { data: statusFunnelTypes } = useQuery([QUERY_KEYS.FUNNEL_STATUS_TYPES, { module: MODULES.ID_TALENT_ACQUISITION }], getFunnelStatusTypes)

  const mutationDocRequired = useMutation(api.requireDocQuery)
  const mutationPostCitation = useMutation(api.postOrPatchCitations)

  const onSubmitAnnouncement = data => {
    const ids = rowSelected?.map(row => row?.id)
    mutationPostCitation
      .mutateAsync({ data, ids: ids })
      .then(() => {
        createAlert(i18n.citation.created, ALERT_LEVEL.SUCCESS)
        clearRowsSelected()
      })
      .catch(err => {
        console.log(err)
        clearRowsSelected()
      })

    clearRowsSelected()
  }

  const onSubmitRequireDoc = data => {
    const ids = rowSelected?.map(row => row?.id)
    mutationDocRequired
      .mutateAsync({ data, ids: ids, type: SECTION_KEYS.JOB_OFFER_APPLICATIONS })
      .then(() => {
        createAlert(i18n.requireDoc.docRequired, ALERT_LEVEL.SUCCESS)
        clearRowsSelected()
      })
      .catch(err => {
        console.log(err)
        clearRowsSelected()
      })

    clearRowsSelected()
  }

  const clearRowsSelected = () => {
    setRowSelected([])
    tableRef.current && tableRef.current.onAllSelected(false)
  }

  const handleChangeState = async data => {
    setValueStatus(data)
    rowSelected.map(row => {
      mutationPostState.mutateAsync(
        {
          vacancy_request_id: vacancy,
          candidatures: [
            {
              dni: row?.dni,
              status: data?.id
            }
          ]
        },
        {
          onSuccess: () => {
            createAlert(i18n.processesTable.statusChanged, ALERT_LEVEL.SUCCESS)
            queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST, vacancy])
            clearRowsSelected()
            setValueStatus(null)
          },
          onError: () => {
            createAlert(i18n.processesTable.statusChangedError, ALERT_LEVEL.ERROR)
          }
        }
      )
    })
  }

  const sortArrayByField = (field, array, ascending = true) => {
    return array.slice().sort((a, b) => {
      const fieldA = field.split('.').reduce((obj, key) => obj[key], a)
      const fieldB = field.split('.').reduce((obj, key) => obj[key], b)

      if (fieldA < fieldB) {
        return ascending ? -1 : 1
      }
      if (fieldA > fieldB) {
        return ascending ? 1 : -1
      }
      return 0
    })
  }

  React.useEffect(() => {
    if (statusFunnelTypes) {
      let objStatusFunnel = []

      statusFunnelTypes.data.map(item => {
        objStatusFunnel.push({ group: { name: item.phase.name }, id: item.id, name: item.name })
      })

      // const sortedStatusFunnel = sortArrayByField('group.name', objStatusFunnel)

      setFunnelStatusTypesByUser(objStatusFunnel)
    }
  }, [statusFunnelTypes])

  const handleExportXLSX = () => {
    const filteredData = data.map(item => {
      let filteredItem = {}
      Object.keys(tableColumns).forEach(key => {
        const field = tableColumns[key].field.split('.')
        let value = item
        if (field[0] === 'nombre') {
          value = `${item.nombre} ${item.apellidos} - ${item.candidatura.name}`
        } else {
          field.forEach(f => (value = value ? value[f] : null))
        }
        filteredItem[tableColumns[key].title] = value
      })
      return filteredItem
    })

    const cleanedData = filteredData.map(item => {
      const { [i18n.templateRequestManager.cv]: _, ...rest } = item
      return rest
    })

    const worksheet = XLSX.utils.json_to_sheet(cleanedData)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Data')
    XLSX.writeFile(workbook, new Date().toISOString() + '_process_details.xlsx')
  }

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <SelectChangeStatusTemplateRequest
          options={funnelStatusTypesByUser}
          label={false}
          placeholder={i18n.profileInfo.selectionStatus}
          onSelectedItemChange={handleChangeState}
          disabled={rowSelected.length === 0}
          currentStatus={valueStatus}
        />

        <ButtonWithIcon
          title={i18n.headerProfileButtons.quoteCall}
          Icon={<ScheduleIcon />}
          onClick={() => setAnnouncementModal(true)}
          disabled={rowSelected.length === 0}
        />
        <ButtonWithIcon title={i18n.peopleTable.requireDoc} onClick={() => setRequireDocModal(true)} Icon={<FileIcon />} disabled={rowSelected.length === 0} />

        <ButtonWithIcon title={i18n.headerProfileButtons.export} Icon={<FileIcon />} onClick={() => handleExportXLSX()} disabled={data.length === 0} />
      </Box>

      <ModalAnnouncementQuery
        open={announcementModal}
        handleClose={() => setAnnouncementModal(false)}
        onSubmit={onSubmitAnnouncement}
        sms={client ? client.sms_available : false}
        rowsSelected={rowSelected.length}
        data={rowSelected}
      />

      <ModalRequireDocQuery open={requireDocModal} handleClose={() => setRequireDocModal(false)} onSubmit={onSubmitRequireDoc} />
    </>
  )
}

const TemplateRequestTableManager = ({ data, i18n, vacancy, client, vacancies }) => {
  const history = useHistory()

  const { module } = useAppContext()

  const mutationPostFavorites = useMutation(api.postFavorites_query)
  const mutationDeleteFavorites = useMutation(api.deleteFavorites_query)

  const tableRef = React.useRef(null)
  const [rowSelected, setRowSelected] = React.useState([])
  const [dataSorted, setDataSorted] = React.useState(data)
  const [selectionEnabled, setSelectionEnabled] = React.useState(true)

  const handleCV = (event, cv) => {
    event.stopPropagation()
    window.open(cv, '_blank')
  }

  const handleFavorite = (event, row) => {
    event.stopPropagation()
    const ids = { ids: row.id }
    const isFavorite = row.is_favorite

    if (!isFavorite) {
      mutationPostFavorites
        .mutateAsync(
          {
            ids,
            module
          },
          mutationFavoritesCallback(ids, module, isFavorite)
        )
        .then(() => {
          queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST, vacancy])
        })
    } else {
      mutationDeleteFavorites
        .mutateAsync(
          {
            ids,
            module
          },
          mutationFavoritesCallback(ids, module, isFavorite)
        )
        .then(() => {
          queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST, vacancy])
        })
    }
  }

  const tableColumns = {
    favorites: {
      title: i18n.templateRequestManager.favorites,
      render: function favoritesColumn(row) {
        return <FavouriteIcon {...row} favorite={row.is_favorite} onClick={event => handleFavorite(event, row)} />
      },
      field: 'is_favorite',
      width: '10%',
      headerStyle: { textAlign: 'center', fontWeight: 'bold' }
    },
    fullname: {
      title: i18n.templateRequestManager.fullname,
      render: function fullnameColumn(row) {
        return (
          <>
            <Box display="flex" alignItems="center" gridGap={5}>
              <MTImage foto={row.foto} alt={row.nombre} inicials={row.nombre.charAt(0) + row.apellidos.charAt(0)} />
              <Box display="flex" gridGap={5} flexDirection="column">
                <MTText value={row.nombre + ' ' + row.apellidos} style={{ fontWeight: '500' }} />
                <MTText value={row.candidatura ? row.candidatura.name : "-"} style={{ fontWeight: '400', fontSize: '12px' }} />
              </Box>
            </Box>
          </>
        )
      },
      field: 'nombre',
      width: '30%'
    },
    telefono: {
      title: i18n.templateRequestManager.phone,
      render: function telefonoColumn(row) {
        if (vacancies?.vacancy_request?.state === 'cancelled' || vacancies?.vacancy_request?.state === 'closed') {
          return <MTText value={'000000000'} style={{ fontWeight: '400', filter: 'blur(3px)', cursor: 'not-allowed' }} />
        } else {
          return <MTText value={row.telefono} style={{ fontWeight: '400' }} />
        }
      },
      field: 'telefono'
    },
    yearExperience: {
      title: i18n.templateRequestManager.yearExperience,
      render: function yearExperienceColumn(row) {
        return <MTText value={row.anos_experiencia} style={{ fontWeight: '400' }} />
      },
      field: 'anos_experiencia'
    },
    levelEducation: {
      title: i18n.templateRequestManager.levelEducation,
      render: function levelEducationColumn(row) {
        return <MTText value={row.formacion} style={{ fontWeight: '400' }} />
      },
      field: 'formacion'
    },
    cv: {
      title: i18n.templateRequestManager.cv,
      render: function cvColumn(row) {
        return vacancies?.vacancy_request?.state === 'cancelled' || vacancies?.vacancy_request?.state === 'closed' ? (
          <IconButton
            color="primary"
            aria-label="CV file"
            component="span"
            onClick={event => event.stopPropagation()}
            style={{ filter: 'blur(3px)', cursor: 'not-allowed' }}
          >
            <FileIcon />
          </IconButton>
        ) : row.cv ? (
          <IconButton color="primary" aria-label="CV file" component="span" onClick={event => handleCV(event, row.cv)}>
            <FileIcon />
          </IconButton>
        ) : (
          <MTText value="-" style={{ fontWeight: '700' }} />
        )
      },
      field: 'cv'
    },
    phase: {
      title: i18n.templateRequestManager.phase,
      render: function VacanciesColumn(row) {
        return (
          <>
            {row?.selection_statuses?.phase?.name ? (
              <Chip
                label={row?.selection_statuses?.phase?.name}
                style={{
                  color: '#000000',
                  backgroundColor: row?.selection_statuses?.phase?.color ? row?.selection_statuses?.phase?.color : '#FFCAA2',
                  fontWeight: '500'
                }}
              />
            ) : (
              <MTText value="-" style={{ fontWeight: '700' }} />
            )}
          </>
        )
      },
      field: 'selection_statuses.phase.name'
    },
    status: {
      title: i18n.templateRequestManager.status,
      render: function ProposedCandidatesColumn(row) {
        return (
          <>
            {row?.selection_statuses?.name ? (
              <Chip
                label={row?.selection_statuses?.name}
                style={{
                  color: '#000000',
                  backgroundColor: row?.selection_statuses?.color ? row?.selection_statuses?.color : '#FFCAA2',
                  fontWeight: '500'
                }}
              />
            ) : (
              <MTText value="-" style={{ fontWeight: '700' }} />
            )}
          </>
        )
      },
      field: 'selection_statuses.name'
    }
  }

  const tableOptions = {
    emptyRowsWhenPaging: false,
    loadingType: 'linear',
    cellStyle: { textAlign: 'center' },
    toolbar: false,
    draggable: false,
    selection: selectionEnabled,
    pageSize: 10
  }

  const columnsArray = Object.values(tableColumns)

  const handleRowClick = (e, row) => {
    localStorage.setItem('latestVacancyRequestId', vacancy);
    history.push(`/talent_acquisition/inbox/profile/${row.id}`)
  }

  const checkRow = data => {
    setRowSelected(data)
  }

  useEffect(() => {
    setDataSorted(data)
  }, [data])

  useEffect(() => {
    vacancies?.vacancy_request?.state === 'closed' || vacancies?.vacancy_request?.state === 'cancelled' || vacancies?.vacancy_request?.state === 'inactive'
      ? setSelectionEnabled(false)
      : setSelectionEnabled(true)
  }, [vacancies])

  return (
    <TemplateRequestTableStyled>
      <ToolbarTable
        rowSelected={rowSelected}
        client={client}
        vacancy={vacancy}
        setRowSelected={setRowSelected}
        tableRef={tableRef}
        data={data}
        tableColumns={tableColumns}
      />
      <MaterialTable
        tableRef={tableRef}
        data={dataSorted}
        columns={columnsArray}
        options={tableOptions}
        onRowClick={selectionEnabled ? handleRowClick : null}
        onSelectionChange={checkRow}
        localization={{
          pagination: {
            labelRowsPerPage: i18n.templateRequestManager.rowsPerPage,
            labelDisplayedRows: `{from}-{to} ${i18n.templateRequestManager.of} {count}`
          },
          body: {
            emptyDataSourceMessage:
              vacancies?.vacancy_request?.state === 'empty' ? i18n.templateRequestManager.emptyDataSource : i18n.templateRequestManager.emptyData
          }
        }}
      />
    </TemplateRequestTableStyled>
  )
}

export default TemplateRequestTableManager
