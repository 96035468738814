import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import i18n from '../../../assets/i18n'
import { SelectChangeStatusTemplateRequestNotGroupStyle } from './styled'

const SelectChangeStatusTemplateRequestNotGroup = ({ options, currentStatus, onSelectedItemChange, disabled = false, label = true, placeholder }) => {
  {
    return (
      <SelectChangeStatusTemplateRequestNotGroupStyle>
        <Autocomplete
          id="change-status-funnel"
          size="small"
          value={currentStatus}
          options={options}
          getOptionLabel={option => option.name}
          style={{ width: 260 }}
          renderInput={params => <TextField {...params} label={label ? i18n.templateRequestManager.statusProcessValidator : null} variant="outlined" placeholder={placeholder} />}
          disableClearable={true}
          onChange={(event, newValue) => {
            onSelectedItemChange(newValue)
          }}
          disabled={disabled === true}
        />
      </SelectChangeStatusTemplateRequestNotGroupStyle>
    )
  }
}

export default SelectChangeStatusTemplateRequestNotGroup
