import React, {Fragment, useEffect, useState} from "react"
import _ from 'lodash'
import SelectChangeStatus from "../select-change-status"
import i18n from "../../../assets/i18n"
import {print} from '../../../utils/print'
import { iso8601Format } from '../../../utils/date'
import {ButtonWithIcon, DeleteIcon, FileIcon, IconSelectionFunnel, PrintIcon, ScheduleIcon} from "../../atoms"
import ModalDeleteUser from "../modal-delete-user"
import ModalDownloadFiles from '../modal-download-files'
import {
  ALERT_LEVEL,
  ALLOWED_EMAILS,
  getCurrentSectionKey,
  INTEGRATIONS,
  MODULES, QUERY_ACTIONS,
  QUERY_KEYS,
  ROLES,
  SECTION_KEYS
} from '../../../config/constants'
import {toast} from 'react-toastify'
import routes from "../../../config/routes"
import {AuthUtils} from "../../../redux/auth";
import IconFile from "../../atoms/icon-file";
import {matchPath, useParams} from "react-router";
import {StatusType} from "../../../models";
import {useMutation} from "@tanstack/react-query";
import * as api from "../../../api";
import queryClient from '../../../api/queryClient'
import {
  mutationChangeStatusCallback,
  mutationPostCitationCallback, refetchQueriesByKeys,
  removeQueriesByKeys, mutationUpdateBlacklistCallback, mutationStatesCandidatesCallback, manageQueriesWithPrefix
} from "../../../api/mutations/mutationCallbacks";
import useProfile from "../../../api/queries/useProfile";
import useStatusTypes from "../../../api/queries/useStatusTypes";
import {useFunnelStatusTypes} from "../../../api/queries/useFunnelStatusTypes";
import {ModalAnnouncementQuery, ModalRequireDocQuery, ModalUpdateBlacklist} from "../index";
import moment from "moment";
import autoStatusDomains from "../../../utils/auto-status-domains";
import SelectChangeFunnel from "../select-change-status-funnel";
import ModalSuggestChangeStatusAnnouncement from "../modal-suggest-change-status-announcement";
import { FeatureGuard } from "../../../utils/guard"
import { Snackbar } from "@material-ui/core"
import CustomAlert from "../../atoms/custom-alert"
import {useAppContext} from "../../../context/appContext";
import {usePersonWithCandidature} from "../../../api/queries/usePerson";

const ProfileActionsButtonsQuery = (
  {
    currentStatus,
    client,
    auth,
    files,
    userName,
    history,
    currentStatusColor,
    hasModalAnnouncement = true,
    location,
    candidatureId,
    setCurrentStatus,
    module,
    user_email,
    statusData,
    fetchMeetingDetails,
    citationModalStatus,
    requestDocumentationModalStatus,
    attachmentFilesModalStatus,
    blacklistModalStatus,
    deleteCandidatureModalStatus,
    suggestChangeStatusModal,
    citationData,
    editedCitation,
    personId = null,
  }) => {
    const currentUserEmail = auth?.email;

    const isCandidateProfileRoutes = [
      routes.profileQuery,
      routes.profileFavoritesQuery,
      routes.profileSaveSearchQuery,
      routes.profilePlanning,
    ];

    const {savedSearchItemId, job_offer_id, job_offer_application_id} = useParams();
    const {createAlert} = useAppContext();

    const [isCandidateProfile, setIsCandidateProfile] = useState(false);
    const [isOnBlacklist, setIsOnBlacklist] = useState(false)
    const [showCitationAction, setShowCitationAction] = useState(false)
    const [statusTypesByUser, setStatusTypesByUser] = useState([])
    const [funnelStatusTypesByUser, setFunnelStatusTypesByUser] = useState([])
    const [showAlert, setShowAlert] = useState(false);
    const [alert, setAlert] = useState({ message: '', severity: '', variant: ''});

    const [numJobs, setNumJobs] = useState(0);

    //const [ meetingDetails, setMeetingDetails ] = useState(null);
    const meetingDetails = null;
    const isPlanningProfile = () => matchPath(location.pathname, {path: routes.profilePlanning}) !== null;


    const {data: profileData} = useProfile(module, candidatureId);
    const {data: personProfileData} = usePersonWithCandidature(personId, job_offer_application_id);
    /*const {data: citationsData, isFetched: isCitationsDataFetched} = useCitations(
        module,
        slug ? {"candidature.id": slug} : filters[SECTION_KEYS.PLANNING],
        range[SECTION_KEYS.PLANNING],
        sort[SECTION_KEYS.PLANNING]);*/

    const { data: statusTypes } = useStatusTypes(module, user_email);
    const { data: statusFunnelTypes } = useFunnelStatusTypes(module);
    const [profileFunnelStatusType, setProfileFunnelStatusType] = useState(statusData)
    const mutationChangeStatus = useMutation(api.changeStatus_query);
    const mutationChangeStatusFromProcess = useMutation(api.postState_query);
    const mutationPostCitation = useMutation(api.postOrPatchCitations);
    const mutationDocRequired = useMutation(api.requireDocQuery);
    const mutationDeleteCandidature = useMutation(api.deleteInboxCandidatureQuery);
    const mutationAddToBlacklist = useMutation(api.addToBlacklist);
    const mutationRemoveFromBlacklist = useMutation(api.removeFromBlacklist);
    const mutationUpdateStatesSelectionFunnel = useMutation(api.updateNewStatesSelectionFunnel);

  useEffect(() => {
    let is_candidate_profile = isCandidateProfileRoutes.some(route => matchPath(location.pathname, {path: route}));
    if (is_candidate_profile) {
      setIsCandidateProfile(true);
    } else {
      setIsCandidateProfile(false);
    }
  }, [location]);

  useEffect(() => {
    if (statusTypes) {
      let showOnlyManualStatus = true;
      if (user_email) {
        autoStatusDomains().map(item => {
          if (user_email.includes(item)) {
            showOnlyManualStatus = false;
          }
        });
      }
      if (showOnlyManualStatus) {
        setStatusTypesByUser(statusTypes.filter(item => item.automatic === false));
      } else {
        setStatusTypesByUser(statusTypes);
      }
    }
  }, [statusTypes]);

  useEffect(() => {
    if (statusFunnelTypes) {
      setFunnelStatusTypesByUser(statusFunnelTypes.data);
    }
  }, [statusFunnelTypes]);

  useEffect(() => {
    if (personProfileData) {
      setIsOnBlacklist(personProfileData.on_blacklist);
    } else if (profileData) {
      setIsOnBlacklist(profileData.on_blacklist);
    }
    setShowCitationAction(true);
  }, [profileData, personProfileData]) // eslint-disable-line react-hooks/exhaustive-deps

  const refetchInboxesQueries = (extraQuery = []) => {
    let queriesToRefetch = extraQuery.concat([
      `${module}:${QUERY_KEYS.INBOX}`,
      `${module}:${QUERY_KEYS.FAVORITES}`,
      `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}`,
    ]);
    refetchQueriesByKeys(queriesToRefetch);
  }

  const removeInboxesQueries = (extraQuery = []) => {
    let queriesToRemove = extraQuery.concat([
      `${module}:${QUERY_KEYS.INBOX}`,
      `${module}:${QUERY_KEYS.FAVORITES}`,
      `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}`,
    ]);
    removeQueriesByKeys(queriesToRemove);
  }

  const displayAlert = (data, callback = null) => {
    setAlert(data);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false)
      callback && callback();
    }, 3500);
  }

  const handleCloseDeleteUserModal = () => deleteCandidatureModalStatus.setIsDeleteCandidatureOpened(false)
  const handleOpenDeleteUserModal = () => deleteCandidatureModalStatus.setIsDeleteCandidatureOpened(true)
  const handleCloseUpdateBlacklistModal = () => blacklistModalStatus.setIsBlacklistOpened(false)
  const handleOpenUpdateBlacklistModal = () => blacklistModalStatus.setIsBlacklistOpened(true)
  const handleClickDocRequired = () => requestDocumentationModalStatus.setIsRequestDocumentationOpened(true)
  const handleClickFiles = () => attachmentFilesModalStatus.setIsAttachmentFilesOpened(true)
  const isTalentReadOnly = AuthUtils.hasRole(ROLES.ROLE_READ_ONLY_talent_acquisition)

  const onSubmitRequireDoc = (data) => {
    if (!job_offer_application_id) {
      let statusObj = _.find(statusTypes, {id: StatusType.DOC_REQUIRED});
      mutationDocRequired.mutateAsync({data, ids: [candidatureId]},
        mutationChangeStatusCallback([candidatureId], module, StatusType.DOC_REQUIRED, profileData, true, statusObj))
        .then(() => {
          if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
            removeInboxesQueries([
              `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
              `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
            ]);
          }
          refetchQueriesByKeys([`${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`]);
          toast.success(i18n.toast_msg.requireDoc.success);
          setCurrentStatus(i18n.status[StatusType.DOC_REQUIRED]);
          refetchInboxesQueries();
        }).catch(() => toast.error(i18n.toast_msg.requireDoc.error));
    } else {
      mutationDocRequired.mutateAsync({data, ids: [personId], type: 'person'})
        .then(() => {
          manageQueriesWithPrefix([
            `${module}:${QUERY_KEYS.OBSERVATIONS}:${personId}`,
            `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
          ], QUERY_ACTIONS.REFETCH);
          createAlert(i18n.toast_msg.requireDoc.success, ALERT_LEVEL.SUCCESS);
        }).catch(() => createAlert(i18n.toast_msg.requireDoc.error, ALERT_LEVEL.ERROR));
    }
  }

  const handleSubmitChangeStatus = (status, date) => {
    let statusObj = _.find(statusTypes, {id: status});
    mutationChangeStatus.mutateAsync({data: [{id: candidatureId, status_id: status, date: date}], module},
      mutationChangeStatusCallback([candidatureId], module, status, profileData, true, statusObj))
      .then(() => {
        if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
          removeInboxesQueries([
            `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
            `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
            `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
            `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
          ]);
        }
        refetchQueriesByKeys([`${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`]);
        toast.success(i18n.toast_msg.changeStatus.success);
        setCurrentStatus(i18n.status[status]);
        refetchInboxesQueries();
      }).catch(() => toast.error(i18n.toast_msg.changeStatus.error));
  }

    const getCurrentIntegration = () => {
        if (auth && INTEGRATIONS) {
            let currentIntegration = _.find(INTEGRATIONS, {event_type: auth.event_type})
            return currentIntegration ? currentIntegration : null;
        }
        return null
    }

  const handleSubmitAnnouncement = data => {
    let id = job_offer_application_id ? job_offer_application_id : candidatureId;
    let ids = [id];
    data.create = true;
    if (job_offer_application_id) {
      data.type = QUERY_KEYS.JOB_OFFER_APPLICATIONS;
    }
    mutationPostCitation.mutateAsync({data, ids}, mutationPostCitationCallback()).then(() => {
        createAlert(i18n.citations.citationCreated, ALERT_LEVEL.SUCCESS);
        if (!job_offer_application_id) {
          let status_id = getCurrentIntegration() ? StatusType.CITED : StatusType.TO_BE_CONFIRMED;
          let statusObj = _.find(statusTypes, {id: status_id});
          let date = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
          let data = [{id: candidatureId, status_id: status_id, date}];
          if (!FeatureGuard.canAccessToFunnelStatus()) {
            mutationChangeStatus.mutateAsync({data, module},
              mutationChangeStatusCallback([candidatureId], module, status_id, profileData, true, statusObj))
              .then(() => {
                if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
                  removeInboxesQueries([
                    `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
                    `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
                    `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
                    `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
                  ]);
                }
                setCurrentStatus(i18n.status[status_id]);
              });
          }
          refetchQueriesByKeys([`${module}:${QUERY_KEYS.CITATIONS}`, `${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`]);
        } else {
          manageQueriesWithPrefix([`${module}:${QUERY_KEYS.CITATIONS}`], QUERY_ACTIONS.REFETCH);
        }
    }).catch(() => {
      createAlert(i18n.citations.citationError, ALERT_LEVEL.ERROR);
    }).finally(() => {
      if (!job_offer_application_id) {
        refetchInboxesQueries();
      }
    });
  }

  const deleteInboxPeople = () => {
    deleteCandidatureModalStatus.setIsDeleteCandidatureOpened(false)
    if (personId) {
      api.deletePerson(personId).then(() => {
        manageQueriesWithPrefix([`${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`], QUERY_ACTIONS.REMOVE);
        createAlert(i18n.headerProfileButtons.personDeleted, ALERT_LEVEL.SUCCESS);
        history.push(routes.job_offer_applications.replace(':job_offer_id', job_offer_id));
      }).catch(() => {
        createAlert(i18n.headerProfileButtons.deletePersonError, ALERT_LEVEL.ERROR);
      });
    } else if (candidatureId) {
      mutationDeleteCandidature.mutateAsync({candidatureId, module})
        .then(() => {
          toast.success(i18n.headerProfileButtons.userDeleted, {
            position: toast.POSITION.BOTTOM_CENTER
          });
          removeInboxesQueries();
          history.push(routes.inbox);
        });
    }
  }

  const handleUpdateBlacklist = () => {
    handleCloseUpdateBlacklistModal();

    let data = [];
    let profile = profileData;
    if (personProfileData) profile = personProfileData;
    if (profile) {
      data.push({dni: profile.dni, email: profile.email, phone: profile.telefono});
    }
    if (!isOnBlacklist) {
      mutationAddToBlacklist.mutateAsync({data}, mutationUpdateBlacklistCallback(profileData, candidatureId, module, true))
        .then(() => {
          setIsOnBlacklist(true);
          if (job_offer_application_id) {
            manageQueriesWithPrefix([
              `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
              `${QUERY_KEYS.PERSON}:${personId}:${job_offer_application_id}`
            ], QUERY_ACTIONS.REFETCH);
          } else {
            removeInboxesQueries();
            toast.success(i18n.headerProfileButtons.added_to_blacklist, {
              position: toast.POSITION.BOTTOM_CENTER
            });

            if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
              removeInboxesQueries([
                `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
                `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
                `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
                `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
              ]);
            }
            refetchQueriesByKeys([
              `${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`,
              `${module}:${QUERY_KEYS.PROFILE}:${candidatureId}`
            ]);
          }
        }).finally(() => {
          if (!job_offer_application_id) refetchInboxesQueries();
      });
    } else {
      mutationRemoveFromBlacklist.mutateAsync({data}, mutationUpdateBlacklistCallback(profileData, candidatureId, module, false))
        .then(() => {
          setIsOnBlacklist(false);
          createAlert(i18n.headerProfileButtons.removed_from_blacklist, ALERT_LEVEL.SUCCESS);

          if (job_offer_application_id) {
            manageQueriesWithPrefix([
              `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
              `${QUERY_KEYS.PERSON}:${personId}:${job_offer_application_id}`
            ], QUERY_ACTIONS.REFETCH);
          } else {
            if ([SECTION_KEYS.SAVED_SEARCHES_ITEM].includes(getCurrentSectionKey())) {
              removeInboxesQueries([
                `${module}:${getCurrentSectionKey()}:${QUERY_KEYS.TOTAL_COUNT}:${savedSearchItemId}`,
                `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:1`,
                `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:2`,
                `${module}:${QUERY_KEYS.SAVED_SEARCHES_ITEM}:${savedSearchItemId}:3`,
              ]);
            }
            refetchQueriesByKeys([
              `${module}:${QUERY_KEYS.OBSERVATIONS}:${candidatureId}`,
              `${module}:${QUERY_KEYS.PROFILE}:${candidatureId}`
            ]);
          }
        }).finally(() => {
          if (!job_offer_application_id) refetchInboxesQueries();
      });
    }
  }

  const handleSubmitChangeFunnelStatus = (status) => {
    const candidateActiveVacancyRequests = profileData?.vacancy_requests ?? [];

    if (localStorage.getItem('latestVacancyRequestId') && candidateActiveVacancyRequests.includes(localStorage.getItem('latestVacancyRequestId'))) {
      handleSubmitChangeFunnelStatusInProcess(localStorage.getItem('latestVacancyRequestId'), profileData.dni, status);
    } else {
      handleSubmitChangeFunnelStatusInCandidate(status);
    }
  }

  const handleSubmitChangeFunnelStatusInProcess = (vacancyRequestId, dni, status) => {
    let payload = {
      vacancy_request_id: vacancyRequestId,
      candidatures: [
        {
          dni: dni,
          status: status.id
        }
      ]
    };

    mutationChangeStatusFromProcess.mutateAsync(
      payload,
      {
        onSuccess: () => {
          displayAlert({ message: i18n.processesTable.statusChanged, severity: ALERT_LEVEL.SUCCESS, variant: 'filled' });
          queryClient.invalidateQueries([QUERY_KEYS.VACANCY_REQUEST, vacancyRequestId])
          mutationStatesCandidatesCallback(module, candidatureId);
        },
        onError: () => {
          displayAlert({ message: i18n.processesTable.statusChangedError, severity: ALERT_LEVEL.ERROR, variant: 'filled' });
        }
      }
    );
  }

  const handleSubmitChangeFunnelStatusInCandidate = (status) => {
    let payload = { id: candidatureId, status_id: status.id, date: iso8601Format() };
    if (job_offer_application_id) {
      payload.id = job_offer_application_id;
      payload.type = SECTION_KEYS.JOB_OFFER_APPLICATIONS;
    }
    mutationUpdateStatesSelectionFunnel.mutateAsync([payload], {
      onSuccess: () => {
        if (job_offer_application_id) {
          manageQueriesWithPrefix([
            `${QUERY_KEYS.JOB_OFFER_APPLICATIONS}:${job_offer_id}`,
            `${module}:${QUERY_KEYS.OBSERVATIONS}:${personId}`,
            `${QUERY_KEYS.PERSON}:${personId}`
          ], QUERY_ACTIONS.REFETCH);
        } else {
          mutationStatesCandidatesCallback(module, candidatureId);
        }
        setProfileFunnelStatusType(status);
        displayAlert({ message: i18n.headerProfileButtons.statusChanged, severity: 'success', variant: 'filled' });
      },
    });
  }

  return (
    <Fragment>
      <Snackbar
        open={showAlert}
        key="custom-alert"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
      >
        <CustomAlert
        severity={alert.severity}
        message={alert.message}
        variant={alert.variant}
        sx={{ width: '100%' }}
        />
      </Snackbar>
      {(!isCandidateProfile || (numJobs <= 1 && FeatureGuard.canAccessToJobOffers()) || (ALLOWED_EMAILS.indexOf(currentUserEmail)<0 && !FeatureGuard.canAccessToJobOffers())) &&
        (!FeatureGuard.canAccessToVideoInterview() && (
          FeatureGuard.canAccessToFunnelStatus() ? (
            <SelectChangeFunnel
              options={funnelStatusTypesByUser}
              currentStatus={profileFunnelStatusType}
              onSelectedItemChange={handleSubmitChangeFunnelStatus}
            />
          ) : (
              currentStatus && (
                  <SelectChangeStatus
                      disabled={isTalentReadOnly}
                      currentStatusColor={currentStatusColor}
                      currentStatus={currentStatus}
                      options={statusTypesByUser}
                      onSubmit={handleSubmitChangeStatus}
                  />
              )
          )
        ))
      }

      {isCandidateProfile && numJobs > 1 && (ALLOWED_EMAILS.indexOf(currentUserEmail)>-1 || FeatureGuard.canAccessToJobOffers()) && !isTalentReadOnly && !FeatureGuard.canAccessToVideoInterview() && FeatureGuard.canAccessToFunnelStatus() &&
        <ButtonWithIcon
          title={i18n.headerProfileButtons.changeState}
          onClick={() => suggestChangeStatusModal.setIsOpenModalSuggestChangeStatus(true)}
          Icon={<IconSelectionFunnel />}
        />}

      {(isCandidateProfile || (!isCandidateProfile && personProfileData && personProfileData.hasOwnProperty('candidature')))
        && !isTalentReadOnly && hasModalAnnouncement && showCitationAction &&
        <ButtonWithIcon
          title={isPlanningProfile() ? i18n.headerProfileButtons.quoteCall : i18n.headerProfileButtons.quoteCall}
          onClick={() => citationModalStatus.setIsCitationOpened(true)}
          Icon={<ScheduleIcon />}
        />}

      {MODULES.ID_TALENT_ACQUISITION === MODULES.currentModule() && !isTalentReadOnly && FeatureGuard.canAccessToDocumentRequest() &&
        <ButtonWithIcon
          title={i18n.peopleTable.requireDoc}
          onClick={handleClickDocRequired}
          Icon={<IconFile />}
        />}

      {!isTalentReadOnly && files && files.length > 0 &&
        <ButtonWithIcon
          title={i18n.headerProfileButtons.docs}
          onClick={handleClickFiles}
          Icon={<FileIcon />}
        />}

      <ButtonWithIcon
        title={i18n.headerProfileButtons.print}
        onClick={() => print('profilePrint')}
        Icon={<PrintIcon />}
      />

      {!isTalentReadOnly &&
        <ButtonWithIcon
          title={isOnBlacklist ? i18n.headerProfileButtons.remove_from_blacklist : i18n.headerProfileButtons.add_to_blacklist}
          onClick={() => handleOpenUpdateBlacklistModal()}
          // Icon={<WarningRoundedIcon/>}
        />}

      {isCandidateProfile && !isTalentReadOnly &&
        <ButtonWithIcon
          remove='true'
          title={i18n.headerProfileButtons.remove}
          onClick={() => handleOpenDeleteUserModal()}
          Icon={<DeleteIcon />}
        />}


      {/* Modals */}
      {!isTalentReadOnly && hasModalAnnouncement && showCitationAction &&
        <ModalAnnouncementQuery
          open={citationModalStatus.isCitationOpened}
          handleClose={() => {
            citationModalStatus.setIsCitationOpened(false)
            citationData.setCitationData(false)
          }}
          onSubmit={handleSubmitAnnouncement}
          sms={client ? client.sms_available : false}
          self={auth ? auth : null}
          isEditFromTab={!!citationData.citationData}
          data={citationData.citationData}
          profileData={profileData}
          meetingData={meetingDetails}
          suggestChangeStatusModal={suggestChangeStatusModal}
          editedCitation={editedCitation}
        />}
      {FeatureGuard.canAccessToFunnelStatus() && <ModalSuggestChangeStatusAnnouncement
          open={suggestChangeStatusModal.isOpenModalSuggestChangeStatus}
          handleClose={() => suggestChangeStatusModal.setIsOpenModalSuggestChangeStatus(false)}
          i18n={i18n}
          profileData={profileData}
          statusFunnelTypes={statusFunnelTypes}
          module={module}
          getApplicationsCallback={(numJobs) => setNumJobs(numJobs)}
        />}

      <ModalUpdateBlacklist
        handleOnClickUpdateBlacklist={handleUpdateBlacklist}
        name={userName}
        open={blacklistModalStatus.isBlacklistOpened}
        isOnBlacklist={isOnBlacklist}
        handleClose={handleCloseUpdateBlacklistModal}
      />

      <ModalDeleteUser
        handleOnClickDelete={deleteInboxPeople}
        name={userName}
        open={deleteCandidatureModalStatus.isDeleteCandidatureOpened}
        handleClose={handleCloseDeleteUserModal}
      />

      <ModalRequireDocQuery
        open={requestDocumentationModalStatus.isRequestDocumentationOpened}
        handleClose={() => requestDocumentationModalStatus.setIsRequestDocumentationOpened(false)}
        onSubmit={onSubmitRequireDoc}
      />

      <ModalDownloadFiles
        files={files}
        open={attachmentFilesModalStatus.isAttachmentFilesOpened}
        handleClose={() => attachmentFilesModalStatus.setIsAttachmentFilesOpened(false)}
        onSubmit={() => { }}
      />
    </Fragment>
  )
}

export default ProfileActionsButtonsQuery;
