import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import { RegularButton } from '../../atoms'
import { ModalChangeAvatar, ModalChangePass } from '../../molecules'
import imagePlaceholder from '../../../assets/images/nothing.png'
import Autocomplete from '@material-ui/lab/Autocomplete';

class InboxPeople extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpenChangeAvatarModal: false,
      isOpenChangePassModal: props.data && !!props.data.force_reset_password,
      data: props.data,
      dataEdited: null,
      image: null,
      lang: props.data ? props.data.lang : null
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.data, state.data) && _.isNil(state.dataEdited)) {
      return { data: props.data, lang: props.data ? props.data.lang : null }
    }
    return null
  }
  
  openChangeAvatarModal = () => this.setState({ isOpenChangeAvatarModal: true })
  closeChangeAvatarModal = () => this.setState({ isOpenChangeAvatarModal: false })
  handleSubmitChangeAvatar = imageBase64 => {
    this.closeChangeAvatarModal()
    this.props.updateAvatar(imageBase64).then(this.updateAvatar)
  }

  openChangePassModal = () => this.setState({ isOpenChangePassModal: true })
  closeChangePassModal = () => this.setState({ isOpenChangePassModal: false })
  handleSubmitChangePass = data => {
    this.closeChangePassModal()
    this.props.changePassword(data)
    setTimeout(() => {
      window.location.reload()
      },3000);

  }

  handleChangeTextInput = e => {
    this.setState({
      dataEdited: {
        ...this.state.dataEdited,
        [e.target.name]: e.target.value
      }
    })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { dataEdited } = this.state
    const { data } = this.props
    dataEdited && this.props.updateProfile({ ...data, ...dataEdited }).then(() => this.setState({ dataEdited: null }))
  }

  onImageError = () => this.setState({ image: null })

  render() {
    const { classes, i18n, url, signOut } = this.props
    const { isOpenChangeAvatarModal, isOpenChangePassModal, data, dataEdited } = this.state
    return (
      <div className={classes.root}>
       {/* <AppBar /> */}
        <Grid container justifyContent="center">
          <div className={classes.avatarContainer}>                        
            <Avatar onClick={this.openChangeAvatarModal} classes={classes} url={url} />           
            <UpdatePassButton classes={classes} onClick={this.openChangePassModal} i18n={i18n} />
            <LogoutButton classes={classes} onClick={signOut} i18n={i18n} />
          </div>

          <Paper className={classes.paper}>
            <form onSubmit={this.handleSubmit}>
              <Grid container style={{ maxWidth: 640 }}>
                <Grid xs={12} item>
                  <Field label={i18n.myProfile.id} value={_.get(data, 'id', '')} disabled />
                </Grid>

                <Grid xs={12} item>
                  <Field label={i18n.myProfile.email} value={_.get(data, 'email', '')} disabled />
                </Grid>

                <Grid xs={12} item>
                  <Field
                    name="name"
                    label={i18n.myProfile.name}
                    value={_.get(dataEdited, 'name', _.get(data, 'name', ''))}
                    onChange={this.handleChangeTextInput}
                  />
                </Grid>

                <Grid xs={12} item>
                  <Field
                    name="surname"
                    label={i18n.myProfile.surname}
                    value={_.get(dataEdited, 'surname', _.get(data, 'surname', ''))}
                    onChange={this.handleChangeTextInput}
                  />
                </Grid>
           
                <Grid xs={12} item>
                  <Autocomplete
                    className={classes.autocomplete}
                    value={
                      this.state.lang
                        ? i18n.myProfile.languages.find(langOption => langOption.value === this.state.lang)
                        : i18n.myProfile.languages.find(langOption => langOption.value === i18n.locale) 
                    }
                    options={i18n.myProfile.languages} 
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={i18n.myProfile.changeLanguage}
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    disableClearable={true}
                    onChange={(event, newValue) => {
                      this.setState({
                        dataEdited: {
                          ...this.state.dataEdited,                        
                          lang: newValue.value && newValue.value !== i18n.locale ? newValue.value : null
                        },
                        lang: newValue.value && newValue.value !== i18n.locale ? newValue.value : null
                      });
                    }}
                  />
                </Grid>
                
              </Grid>

              <RegularButton className={classes.button} title={i18n.myProfile.update} disabled={_.isNil(dataEdited)} />
            </form>
          </Paper>
        </Grid>

        <ModalChangeAvatar open={isOpenChangeAvatarModal} handleClose={this.closeChangeAvatarModal} onSubmit={this.handleSubmitChangeAvatar} />
        <ModalChangePass open={isOpenChangePassModal} handleClose={this.closeChangePassModal} onSubmit={this.handleSubmitChangePass} forceResetPassword={ this.props.data && this.props.data.force_reset_password } />
      </div>
    )
  }
}

InboxPeople.propTypes = {
  i18n: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  signOut: PropTypes.func,
  fetchMe: PropTypes.func,
  imageBase64: PropTypes.func,
  data: PropTypes.object,
  url: PropTypes.string,
  updateAvatar: PropTypes.func,
  updateProfile: PropTypes.func,
  setUrl: PropTypes.func,
  changePassword: PropTypes.func,
  integrateWithTeams: PropTypes.func
}

const Field = ({ label, value, ...props }) => <TextField fullWidth {...props} label={label} value={value} margin="normal" variant="outlined" />

Field.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

const LogoutButton = ({ onClick, classes, i18n }) => (
  <div className={classes.logOut}>
    <Button onClick={onClick} color="secondary">
      {i18n.myProfile.logOut}
    </Button>
  </div>
)

LogoutButton.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  i18n: PropTypes.object
}

const UpdatePassButton = ({ onClick, classes, i18n }) => (
  <div className={classes.logOut}>
    <Button onClick={onClick}>{i18n.myProfile.updatePass}</Button>
  </div>
)

UpdatePassButton.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  i18n: PropTypes.object
}

const Avatar = ({ onClick, classes, url }) => (
  <div className={classes.avatar} onClick={onClick}>
    <img src={url ? url : imagePlaceholder} alt="avatar" />
    <div>
      <EditIcon color="primary" fontSize="large" className={classes.avatarEdit} />
    </div>
  </div>
)

Avatar.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  url: PropTypes.string
}

export default InboxPeople
