export const someArgumentIs = (targetValue, ...valuesToCheck) => {
  return valuesToCheck.some(val => val === targetValue);
}

export const isJSON = (jsonString) => {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}