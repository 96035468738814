import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import { Grid, Divider, Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import moment from 'moment'

import NorFound from '../not-found/view'

import useVacancyRequest from '../../../api/queries/useVacancyRequest'

import { BackButton, InputField, SpinnerIcon } from '../../atoms'
import { TemplateRequestTableManager } from '../../organism'
import { SingleDate } from '../../molecules'

import * as api from '../../../api/index'
import i18n from '../../../assets/i18n'

import { TemplateRequestManagerStyled } from './styled'
import styles from '../not-found/styles'
import { useRequirements } from '../../../api/queries/useRequirements'
import { useAppContext } from '../../../context/appContext'
import { ALERT_LEVEL } from '../../../config/constants'

const VACANCY_STATES = {
  CANCELLED: 'cancelled',
  REJECTED: 'rejected',
  ACTIVE: 'active',
  CLOSED: 'closed',
  PENDING: 'pending',
  EMPTY: 'empty'
}

let statusOptions = [
  { id: VACANCY_STATES.CANCELLED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.CANCELLED] },
  { id: VACANCY_STATES.REJECTED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.REJECTED] },
  { id: VACANCY_STATES.ACTIVE, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.ACTIVE] },
  { id: VACANCY_STATES.CLOSED, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.CLOSED] },
  { id: VACANCY_STATES.PENDING, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.PENDING] },
  { id: VACANCY_STATES.EMPTY, value: i18n.templateRequestManager.vacancyStates[VACANCY_STATES.EMPTY] }
]

let typeContractOptions = [
  { id: 1, name: i18n.templateRequestManager.typeContractIndefinite },
  { id: 2, name: i18n.templateRequestManager.typeContractTemporary },
  { id: 3, name: i18n.templateRequestManager.typeContractInterim }
]

const ProcessShowTemplateRequest = ({ user, client }) => {
  const { createAlert } = useAppContext()

  const useStyles = makeStyles(styles)
  const history = useHistory()

  const theme = useTheme()
  const classes = useStyles(theme)

  const { vacancyRequestId } = useParams()

  const { data: vacancies, isFetched, isError, isLoading } = useVacancyRequest(vacancyRequestId)
  const { data: requirementsData, isLoading: requirementsLoading } = useRequirements(
    vacancies?.vacancy_request?.candidature_settings?.id,
    vacancies?.vacancy_request?.candidature_settings !== ''
  )

  const [defaultValue, setDefaultValue] = React.useState({})
  const [requirementConstraints, setRequirementConstraints] = React.useState([])
  const [requirementScoringFields, setRequirementScoringFields] = React.useState([])

  const filtrarOptionsRequirements = obj => {
    let resultado = []
    for (let propiedad in obj) {
      if (typeof obj[propiedad] === 'string' && propiedad !== 'unidad' && propiedad !== 'candidatura_id') {
        resultado.push({ id: propiedad, name: obj[propiedad] })
      }
    }
    return resultado
  }

  const handleChange = (name, _, value) => {
    setDefaultValue(prevStates => ({
      ...prevStates,
      [name]: value
    }))
  }

  const staff = user.staffs

  let resultOptionsWorkplace = []
  staff.map(place =>
    place.client_places.map(clientPlace => {
      resultOptionsWorkplace.push({
        id: clientPlace.id,
        name: clientPlace.name
      })
    })
  )

  useEffect(() => {
    if (requirementsData && vacancies) {
      const dataRequirementsConstraints = filtrarOptionsRequirements(vacancies?.vacancy_request?.filters?.constraints)
      const filteredResults = requirementsData?.filter(item2 =>
        dataRequirementsConstraints.some(item1 => item2.question_remote_name === item1.id && item2.answer_value === item1.name)
      )
      setRequirementConstraints(filteredResults)

      const dataRequirementsScoringFields = filtrarOptionsRequirements(vacancies?.vacancy_request?.filters?.scoring_fields)
      const filteredResultsScoringFields = requirementsData?.filter(item2 =>
        dataRequirementsScoringFields.some(item1 => item2.question_remote_name === item1.id && item2.answer_value === item1.name)
      )
      setRequirementScoringFields(filteredResultsScoringFields)

      if (vacancies?.vacancy_request?.state !== 'closed') {
        const countHired = vacancies.candidates.filter(candidate => candidate?.selection_statuses?.name === 'Contratados').length
        if (countHired >= vacancies?.vacancy_request?.vacancies) {
          api
            .updateNewStatesSelectionCandidate({ id: 'closed' }, vacancies?.vacancy_request?.id)
            .then(() => {
              createAlert(i18n.templateRequestManager.updatedProcessStatus, ALERT_LEVEL.SUCCESS)
              history.goBack()
            })
            .catch(err => {
              createAlert(err.message, ALERT_LEVEL.ERROR)
            })
        }
      }
    }
  }, [vacancies, requirementsData])

  if (isError) {
    return <NorFound i18n={i18n} classes={classes} />
  }

  return (
    <TemplateRequestManagerStyled>
      <Grid container className="title-container">
        <Grid item sm={12}>
          <BackButton
            title={i18n.templateRequestManager.backToTemplateRequestManager}
            onClick={() => {
              history.goBack()
            }}
          />
        </Grid>
      </Grid>

      <Divider variant="inset" />

      <Grid container className="create-process-container">
        <Box mb={2}>
          <Typography
            variant="h1"
            style={{ color: '#000000', display: 'inline', fontSize: '28px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '1.167' }}
          >
            {i18n.templateRequestManager.showProcess}
          </Typography>
        </Box>

        <Grid item className="sectionSecond" alignItems="center">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Box display="flex" alignItems="center" flexWrap="wrap" gridGap={10}>
                <Box flexGrow={1}></Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label>{i18n.templateRequestManager.requestor} </label>
                {isFetched ? (
                  <>
                    <Typography variant="subtitle2" sx={{ fontSize: '14px' }}>
                      {vacancies?.vacancy_request?.user?.name + ' ' + vacancies?.vacancy_request?.user?.surname}
                    </Typography>
                    <Typography variant="body1" sx={{ fontWeight: '400', fontSize: '12px' }}>
                      {vacancies?.vacancy_request?.user?.email}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Skeleton variant="rect" width="100%" height={10} />
                    <Skeleton variant="rect" width="100%" height={10} style={{ marginTop: 2 }} />
                  </>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.reason}</label>
                {isFetched ? (
                  <InputField
                    value={i18n.templateRequestManager.vacancyReasons[vacancies?.vacancy_request?.reason]}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
            
            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.workplace}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.placeholderWorkplace}
                    value={vacancies?.vacancy_request?.client_place?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.position}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.placeholderPosition}
                    value={vacancies?.vacancy_request?.candidature_settings?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.numberVacanciesRequest}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.numberVacanciesRequest}
                    value={vacancies?.vacancy_request?.vacancies + '/' + vacancies?.vacancy_request?.requested_vacancies}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box className="container-item-text">
                <label className="required">{i18n.templateRequestManager.dateCreated} </label>
                {isFetched ? (
                  <SingleDate date={moment(vacancies?.vacancy_request?.created_at)} readOnly={true} />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            {vacancies?.vacancy_request?.validation_date_at ? (
              <Grid item xs={12} sm={6} lg={3}>
                <Box className="container-item-text">
                  <label className="required">{i18n.templateRequestManager.dateValidation} </label>
                  {isFetched ? (
                    <SingleDate date={moment(vacancies?.vacancy_request?.validation_date_at)} readOnly={true} />
                  ) : (
                    <Skeleton variant="rect" width="100%" height={10} />
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} lg={3}>
                <Box className="container-item-text">
                  <label className="required">{i18n.templateRequestManager.dateValidation} </label>
                  {isFetched ? (
                    <Typography variant="subtitle2" sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                      -
                    </Typography>
                  ) : (
                    <Skeleton variant="rect" width="100%" height={10} />
                  )}
                </Box>
              </Grid>
            )}

            <Grid item xs={12} sm={6} lg={3}>
              <Box className="container-item-text">
                <label className="required">{i18n.templateRequestManager.dateIncorporation} </label>
                {isFetched ? (
                  <SingleDate date={moment(vacancies?.vacancy_request?.start_date_at)} readOnly={true} />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <Box>
                <label className="required">{i18n.templateRequestManager.typeContract}</label>
                {isFetched ? (
                  <InputField
                    placeholder={i18n.templateRequestManager.placeholderPosition}
                    value={typeContractOptions.find(option => option.id === parseInt(vacancies?.vacancy_request?.contract_type))?.name}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Box>
                <label className="required">{i18n.templateRequestManager.newStatusProcess} </label>
                {isFetched ? (
                  <Autocomplete
                    size="small"
                    className="autocomplete-custom"
                    value={defaultValue.status || statusOptions.find(option => option.id === vacancies?.vacancy_request?.state)}
                    options={statusOptions}
                    getOptionLabel={option => option.value}
                    onChange={(_, value) => handleChange('status', _, value)}
                    renderInput={params => <TextField {...params} placeholder={i18n.templateRequestManager.placeholderStatusProcess} variant="outlined" />}
                    disableClearable={true}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Box>
                <label className="required">{i18n.templateRequestManager.mandatoryRequirements}</label>
                {!requirementsLoading && isFetched ? (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    className="autocomplete-custom"
                    value={requirementConstraints}
                    options={requirementConstraints}
                    getOptionLabel={option => option.question_name + ' - ' + option.answer_value}
                    renderInput={params => (
                      <TextField {...params} placeholder={i18n.templateRequestManager.placeholderRequirementsRequired} variant="outlined" />
                    )}
                    disableClearable={true}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Box>
                <label>{i18n.templateRequestManager.desirableRequirements}</label>
                {!requirementsLoading && isFetched ? (
                  <Autocomplete
                    multiple
                    limitTags={2}
                    size="small"
                    className="autocomplete-custom"
                    value={requirementScoringFields}
                    options={requirementScoringFields}
                    getOptionLabel={option => option.question_name + ' - ' + option.answer_value}
                    renderInput={params => (
                      <TextField {...params} placeholder={i18n.templateRequestManager.placeholderRequirementsDesirable} variant="outlined" />
                    )}
                    disableClearable={true}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <label>{i18n.templateRequestManager.observations}</label>
                {isFetched ? (
                  <textarea
                    className="textarea-question"
                    rows={2}
                    name="observations"
                    placeholder={i18n.templateRequestManager.placeholderObservations}
                    value={vacancies?.vacancy_request?.information?.observations}
                    disabled={true}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={10} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Box mb={2}>
          <Typography
            variant="h1"
            style={{ color: '#000000', display: 'inline', fontSize: '28px', fontFamily: 'Inter', fontWeight: '600', lineHeight: '1.167' }}
          >
            {i18n.templateRequestManager.candidates}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {!isLoading ? (
              <TemplateRequestTableManager data={vacancies?.candidates} i18n={i18n} vacancy={vacancyRequestId} client={client} vacancies={vacancies} />
            ) : (
              <SpinnerIcon />
            )}
          </Grid>
        </Grid>
      </Grid>
    </TemplateRequestManagerStyled>
  )
}

export default ProcessShowTemplateRequest
